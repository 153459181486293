import React from 'react';
import {
	FormGroup,
	Label,
	Badge
} from 'reactstrap';
import classnames from 'classnames';

const FormField = render => ({
	input,
	meta,
	type,
	label,
	id,
	required,
	...attributes
}) => (
	<FormGroup check={type === 'radio' || type === 'checkbox'} className={classnames(attributes.className)}>
		{type === 'radio' || type === 'checkbox'
			? (
				<Label check>
					{render(input, meta, label, id, type, attributes)} {required ? <strong>{label}</strong> : label}
				</Label>
			)
			: (
				<>
					{label && <Label for={id}>{required ? <strong>{label}</strong> : label}</Label>}
					{render(input, meta, label, id, type, attributes)}
				</>
			)
		}
		{meta.error && (meta.touched || attributes.validateOnLoad) && <Badge className="d-block text-wrap" color="danger">{meta.error}</Badge>}
	</FormGroup>
);

export default FormField;
