import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	companyGetAllRequest: false,
	companyGetAllOk: true,
	companyGetAllMessages: [],
	companyGetByIdRequest: false,
	companyGetByIdOk: true,
	companyGetByIdMessages: [],
	companyAddRequest: false,
	companyAddOk: true,
	companyAddMessages: [],
	companyEditRequest: false,
	companyEditOk: true,
	companyEditMessages: [],
	companyDeleteRequest: false,
	companyDeleteOk: true,
	companyDeleteMessages: [],
	companies: [],
	company: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.COMPANY_GETALL_START:
			return {
				...state,
				companyGetAllRequest: true,
				companyGetAllOk: true,
				companyGetAllMessages: [],
				companies: initialState.companies
			};
		case actionTypes.COMPANY_GETALL_SUCCESS:
			return {
				...state,
				companyGetAllRequest: false,
				companyGetAllOk: true,
				companyGetAllMessages: [],
				companies: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMPANY_GETALL_ERROR:
			return {
				...state,
				companyGetAllRequest: false,
				companyGetAllOk: false,
				companyGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.COMPANY_GETBYID_START:
			return {
				...state,
				companyGetByIdRequest: true,
				companyGetByIdOk: true,
				companyGetByIdMessages: [],
				company: null
			};
		case actionTypes.COMPANY_GETBYID_SUCCESS:
			return {
				...state,
				companyGetByIdRequest: false,
				companyGetByIdOk: true,
				companyGetByIdMessages: [],
				company: action.payload
			};
		case actionTypes.COMPANY_GETBYID_ERROR:
			return {
				...state,
				companyGetByIdRequest: false,
				companyGetByIdOk: false,
				companyGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.COMPANY_ADD_START:
			return {
				...state,
				companyAddRequest: true,
				companyAddMessages: [],
				companyAddOk: true
			};
		case actionTypes.COMPANY_ADD_SUCCESS:
			return {
				...state,
				companyAddRequest: false,
				companyAddOk: true,
				companyAddMessages: [],
				companies: [
					...state.companies,
					action.payload
				]
			};
		case actionTypes.COMPANY_ADD_ERROR:
			return {
				...state,
				companyAddRequest: false,
				companyAddOk: false,
				companyAddMessages: deepCopy(action.payload)
			};
		case actionTypes.COMPANY_EDIT_START:
			return {
				...state,
				companyEditRequest: true,
				companyEditMessages: [],
				companyEditOk: true
			};
		case actionTypes.COMPANY_EDIT_SUCCESS:
			return {
				...state,
				companyEditRequest: false,
				companyEditOk: true,
				companyEditMessages: [],
				companies: [
					...state.companies.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.COMPANY_EDIT_ERROR:
			return {
				...state,
				companyEditRequest: false,
				companyEditOk: false,
				companyEditMessages: deepCopy(action.payload)
			};
		case actionTypes.COMPANY_DELETE_START:
			return {
				...state,
				companyDeleteRequest: true,
				companyDeleteMessages: [],
				companyDeleteOk: true
			};
		case actionTypes.COMPANY_DELETE_SUCCESS:
			return {
				...state,
				companyDeleteRequest: false,
				companyDeleteOk: true,
				companyDeleteMessages: [],
				companies: [
					...state.companies.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.COMPANY_DELETE_ERROR:
			return {
				...state,
				companyDeleteRequest: false,
				companyDeleteOk: false,
				companyDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
