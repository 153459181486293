export default {
	Login: 0,
	Home: 1,
	Dashboard: 2,
	Hardware: 3,
	HardwareAdd: 4,
	HardwareEdit: 5,
	HardwareCopy: 6,
	HardwareDelete: 7,
	Communication: 8,
	CommunicationAdd: 9,
	CommunicationEdit: 10,
	CommunicationCopy: 11,
	CommunicationDelete: 12,
	Configuration: 13,
	Company: 14,
	CompanyAdd: 15,
	CompanyEdit: 16,
	CompanyDelete: 17,
	Branch: 18,
	BranchAdd: 19,
	BranchEdit: 20,
	BranchDelete: 21,
	Department: 22,
	DepartmentAdd: 23,
	DepartmentEdit: 24,
	DepartmentDelete: 25,
	Brand: 26,
	BrandAdd: 27,
	BrandEdit: 28,
	BrandDelete: 29,
	Model: 30,
	ModelAdd: 31,
	ModelEdit: 32,
	ModelDelete: 33,
	Supplier: 34,
	SupplierAdd: 35,
	SupplierEdit: 36,
	SupplierDelete: 37,
	HardwareType: 38,
	HardwareTypeAdd: 39,
	HardwareTypeEdit: 40,
	HardwareTypeDelete: 41,
	CommunicationTechnology: 42,
	CommunicationTechnologyAdd: 43,
	CommunicationTechnologyEdit: 44,
	CommunicationTechnologyDelete: 45,
	CommunicationUses: 46,
	CommunicationUsesAdd: 47,
	CommunicationUsesEdit: 48,
	CommunicationUsesDelete: 49,
	Service: 50,
	ServiceAdd: 51,
	ServiceEdit: 52,
	ServiceDelete: 53,
	MovementType: 54,
	MovementTypeAdd: 55,
	MovementTypeEdit: 56,
	MovementTypeDelete: 57,
	MovementHardware: 58,
	MovementHardwareAdd: 59,
	MovementHardwareEdit: 60,
	MovementHardwareDelete: 61,
	MovementCommunication: 62,
	MovementCommunicationAdd: 63,
	MovementCommunicationEdit: 64,
	MovementCommunicationDelete: 65,
	CommunicationType: 66,
	CommunicationTypeAdd: 67,
	CommunicationTypeEdit: 68,
	CommunicationTypeDelete: 69,
};
