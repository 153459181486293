import React from 'react';
import { connect } from 'react-redux';
import LoginComponent from '~/components/Login/Login';
import EmployeeActions from '~/actions/Employee';

class Login extends React.PureComponent {
	async componentDidMount() {
		const { onLogOut } = this.props;
		await onLogOut();
	}

	render() {
		return <LoginComponent {...this.props} />;
	}
}

const mapStateToProps = state => ({
	loginRequest: state.loginRequest,
	loginMessages: state.loginMessages,
	loginOK: state.loginOK,
	employee: state.employee
});

const mapDispatchToProps = {
	onLogIn: EmployeeActions.logIn,
	onLogOut: EmployeeActions.logOut,
	clearCache: EmployeeActions.clearCache
};

export default connect(({ Employee }) => mapStateToProps(Employee), mapDispatchToProps)(Login);
