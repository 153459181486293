import React from 'react';
import PropTypes from 'prop-types';

import './FullPageLoader.scss';

const FullPageLoader = ({ isVisible, message, icon }) => (
	isVisible
		? (
			<div className="position-fixed fullPageLoader">
				<div className="fullPageLoader__content d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
					<div className="fullPageLoader__icon">
						<i className={`fa ${icon} fa-5x fa-fw`} />
					</div>
					{message && (<div className="fullPageLoader__message">{message}</div>)}
				</div>
			</div>
		)
		: null
);

FullPageLoader.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	message: PropTypes.string,
	icon: PropTypes.string
};

FullPageLoader.defaultProps = {
	message: '',
	icon: 'fa-spinner fa-pulse'
};

export default React.memo(FullPageLoader);
