import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	communicationGetAllRequest: false,
	communicationGetAllOk: true,
	communicationGetAllMessages: [],
	communicationGetByIdRequest: false,
	communicationGetByIdOk: true,
	communicationGetByIdMessages: [],
	communicationAddRequest: false,
	communicationAddOk: true,
	communicationAddMessages: [],
	communicationEditRequest: false,
	communicationEditOk: true,
	communicationEditMessages: [],
	communicationDeleteRequest: false,
	communicationDeleteOk: true,
	communicationDeleteMessages: [],
	communicationSearchRequest: false,
	communicationSearchOk: true,
	communicationSearchMessages: [],
	communications: [],
	communication: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.COMMUNICATION_GETALL_START:
			return {
				...state,
				communicationGetAllRequest: true,
				communicationGetAllOk: true,
				communicationGetAllMessages: [],
				communications: initialState.communications
			};
		case actionTypes.COMMUNICATION_GETALL_SUCCESS:
			return {
				...state,
				communicationGetAllRequest: false,
				communicationGetAllOk: true,
				communicationGetAllMessages: [],
				communications: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMMUNICATION_GETALL_ERROR:
			return {
				...state,
				communicationGetAllRequest: false,
				communicationGetAllOk: false,
				communicationGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_GETBYID_START:
			return {
				...state,
				communicationGetByIdRequest: true,
				communicationGetByIdOk: true,
				communicationGetByIdMessages: [],
				communication: null
			};
		case actionTypes.COMMUNICATION_GETBYID_SUCCESS:
			return {
				...state,
				communicationGetByIdRequest: false,
				communicationGetByIdOk: true,
				communicationGetByIdMessages: [],
				communication: action.payload
			};
		case actionTypes.COMMUNICATION_GETBYID_ERROR:
			return {
				...state,
				communicationGetByIdRequest: false,
				communicationGetByIdOk: false,
				communicationGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_ADD_START:
			return {
				...state,
				communicationAddRequest: true,
				communicationAddMessages: [],
				communicationAddOk: true,
			};
		case actionTypes.COMMUNICATION_ADD_SUCCESS:
			return {
				...state,
				communicationAddRequest: false,
				communicationAddOk: true,
				communicationAddMessages: [],
				communications: [
					...state.communications,
					action.payload
				]
			};
		case actionTypes.COMMUNICATION_ADD_ERROR:
			return {
				...state,
				communicationAddRequest: false,
				communicationAddOk: false,
				communicationAddMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_EDIT_START:
			return {
				...state,
				communicationEditRequest: true,
				communicationEditMessages: [],
				communicationEditOk: true
			};
		case actionTypes.COMMUNICATION_EDIT_SUCCESS:
			return {
				...state,
				communicationEditRequest: false,
				communicationEditOk: true,
				communicationEditMessages: [],
				communications: [
					...state.communications.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.COMMUNICATION_EDIT_ERROR:
			return {
				...state,
				communicationEditRequest: false,
				communicationEditOk: false,
				communicationEditMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_DELETE_START:
			return {
				...state,
				communicationDeleteRequest: true,
				communicationDeleteMessages: [],
				communicationDeleteOk: true
			};
		case actionTypes.COMMUNICATION_DELETE_SUCCESS:
			return {
				...state,
				communicationDeleteRequest: false,
				communicationDeleteOk: true,
				communicationDeleteMessages: [],
				communications: [
					...state.communications.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.COMMUNICATION_DELETE_ERROR:
			return {
				...state,
				communicationDeleteRequest: false,
				communicationDeleteOk: false,
				communicationDeleteMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_SEARCH_START:
			return {
				...state,
				communicationSearchRequest: true,
				communicationSearchMessages: [],
				communicationSearchOk: true,
				communications: []
			};
		case actionTypes.COMMUNICATION_SEARCH_SUCCESS:
			return {
				...state,
				communicationSearchRequest: false,
				communicationSearchOk: true,
				communicationSearchMessages: [],
				communications: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMMUNICATION_SEARCH_ERROR:
			return {
				...state,
				communicationSearchRequest: false,
				communicationSearchOk: false,
				communicationSearchMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATION_SHOW_LOW:
			return {
				...state,
				communications: [
					...state.communications.filter(x => x.low === null)
				]
			};
		default:
			return state;
	}
};
