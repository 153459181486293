import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.CommunicationType,
		path: '/communicationType',
		exact: true,
		name: 'Tipo de communicación',
		component: Loadable({
			loader: () => import('~/routes/CommunicationType/CommunicationTypeList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationTypeAdd,
		path: '/communicationType/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/CommunicationType/CommunicationTypeAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationTypeEdit,
		path: '/communicationType/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/CommunicationType/CommunicationTypeEdit'),
			loading: Loading,
		})
	}
];
