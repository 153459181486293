import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	movementGetAllRequest: false,
	movementGetAllOk: true,
	movementGetAllMessages: [],
	movementGetByIdRequest: false,
	movementGetByIdOk: true,
	movementGetByIdMessages: [],
	movementAddRequest: false,
	movementAddOk: true,
	movementAddMessages: [],
	movementEditRequest: false,
	movementEditOk: true,
	movementEditMessages: [],
	movementDeleteRequest: false,
	movementDeleteOk: true,
	movementDeleteMessages: [],
	movementGetByHardwareRequest: false,
	movementGetByHardwareOk: true,
	movementGetByHardwareMessages: [],
	movementGetByCommunicationRequest: false,
	movementGetByCommunicationOk: true,
	movementGetByCommunicationMessages: [],
	movements: [],
	movement: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.MOVEMENT_GETALL_START:
			return {
				...state,
				movementGetAllRequest: true,
				movementGetAllOk: true,
				movementGetAllMessages: [],
				movements: initialState.movements
			};
		case actionTypes.MOVEMENT_GETALL_SUCCESS:
			return {
				...state,
				movementGetAllRequest: false,
				movementGetAllOk: true,
				movementGetAllMessages: [],
				movements: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.MOVEMENT_GETALL_ERROR:
			return {
				...state,
				movementGetAllRequest: false,
				movementGetAllOk: false,
				movementGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_GETBYID_START:
			return {
				...state,
				movementGetByIdRequest: true,
				movementGetByIdOk: true,
				movementGetByIdMessages: [],
				movement: null
			};
		case actionTypes.MOVEMENT_GETBYID_SUCCESS:
			return {
				...state,
				movementGetByIdRequest: false,
				movementGetByIdOk: true,
				movementGetByIdMessages: [],
				movement: action.payload
			};
		case actionTypes.MOVEMENT_GETBYID_ERROR:
			return {
				...state,
				movementGetByIdRequest: false,
				movementGetByIdOk: false,
				movementGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_ADD_START:
			return {
				...state,
				movementAddRequest: true,
				movementAddMessages: [],
				movementAddOk: true,
			};
		case actionTypes.MOVEMENT_ADD_SUCCESS:
			return {
				...state,
				movementAddRequest: false,
				movementAddOk: true,
				movementAddMessages: [],
				movements: [
					...state.movements,
					action.payload
				]
			};
		case actionTypes.MOVEMENT_ADD_ERROR:
			return {
				...state,
				movementAddRequest: false,
				movementAddOk: false,
				movementAddMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_EDIT_START:
			return {
				...state,
				movementEditRequest: true,
				movementEditMessages: [],
				movementEditOk: true
			};
		case actionTypes.MOVEMENT_EDIT_SUCCESS:
			return {
				...state,
				movementEditRequest: false,
				movementEditOk: true,
				movementEditMessages: [],
				movements: [
					...state.movements.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.MOVEMENT_EDIT_ERROR:
			return {
				...state,
				movementEditRequest: false,
				movementEditOk: false,
				movementEditMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_DELETE_START:
			return {
				...state,
				movementDeleteRequest: true,
				movementDeleteMessages: [],
				movementDeleteOk: true
			};
		case actionTypes.MOVEMENT_DELETE_SUCCESS:
			return {
				...state,
				movementDeleteRequest: false,
				movementDeleteOk: true,
				movementDeleteMessages: [],
				movements: [
					...state.movements.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.MOVEMENT_DELETE_ERROR:
			return {
				...state,
				movementDeleteRequest: false,
				movementDeleteOk: false,
				movementDeleteMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_GETBYHARDWARE_START:
			return {
				...state,
				movementGetByHardwareRequest: true,
				movementGetByHardwareOk: true,
				movementGetByHardwareMessages: [],
				movements: initialState.movements
			};
		case actionTypes.MOVEMENT_GETBYHARDWARE_SUCCESS:
			return {
				...state,
				movementGetByHardwareRequest: false,
				movementGetByHardwareOk: true,
				movementGetByHardwareMessages: [],
				movements: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.MOVEMENT_GETBYHARDWARE_ERROR:
			return {
				...state,
				movementGetByHardwareRequest: false,
				movementGetByHardwareOk: false,
				movementGetByHardwareMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENT_GETBYCOMMUNICATION_START:
			return {
				...state,
				movementGetByCommunicationRequest: true,
				movementGetByCommunicationOk: true,
				movementGetByCommunicationMessages: [],
				movements: initialState.movements
			};
		case actionTypes.MOVEMENT_GETBYCOMMUNICATION_SUCCESS:
			return {
				...state,
				movementGetByCommunicationRequest: false,
				movementGetByCommunicationOk: true,
				movementGetByCommunicationMessages: [],
				movements: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.MOVEMENT_GETBYCOMMUNICATION_ERROR:
			return {
				...state,
				movementGetByCommunicationRequest: false,
				movementGetByCommunicationOk: false,
				movementGetByCommunicationMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
