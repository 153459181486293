import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	communicationTechnologyGetAllRequest: false,
	communicationTechnologyGetAllOk: true,
	communicationTechnologyGetAllMessages: [],
	communicationTechnologyGetByIdRequest: false,
	communicationTechnologyGetByIdOk: true,
	communicationTechnologyGetByIdMessages: [],
	communicationTechnologyAddRequest: false,
	communicationTechnologyAddOk: true,
	communicationTechnologyAddMessages: [],
	communicationTechnologyEditRequest: false,
	communicationTechnologyEditOk: true,
	communicationTechnologyEditMessages: [],
	communicationTechnologyDeleteRequest: false,
	communicationTechnologyDeleteOk: true,
	communicationTechnologyDeleteMessages: [],
	communicationTechnologies: [],
	communicationTechnology: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_START:
			return {
				...state,
				communicationTechnologyGetAllRequest: true,
				communicationTechnologyGetAllOk: true,
				communicationTechnologyGetAllMessages: [],
				communicationTechnologies: initialState.communicationTechnologies
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_SUCCESS:
			return {
				...state,
				communicationTechnologyGetAllRequest: false,
				communicationTechnologyGetAllOk: true,
				communicationTechnologyGetAllMessages: [],
				communicationTechnologies: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_ERROR:
			return {
				...state,
				communicationTechnologyGetAllRequest: false,
				communicationTechnologyGetAllOk: false,
				communicationTechnologyGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETBYID_START:
			return {
				...state,
				communicationTechnologyGetByIdRequest: true,
				communicationTechnologyGetByIdOk: true,
				communicationTechnologyGetByIdMessages: [],
				communicationTechnology: null
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETBYID_SUCCESS:
			return {
				...state,
				communicationTechnologyGetByIdRequest: false,
				communicationTechnologyGetByIdOk: true,
				communicationTechnologyGetByIdMessages: [],
				communicationTechnology: action.payload
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETBYID_ERROR:
			return {
				...state,
				communicationTechnologyGetByIdRequest: false,
				communicationTechnologyGetByIdOk: false,
				communicationTechnologyGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_ADD_START:
			return {
				...state,
				communicationTechnologyAddRequest: true,
				communicationTechnologyAddMessages: [],
				communicationTechnologyAddOk: true,
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_ADD_SUCCESS:
			return {
				...state,
				communicationTechnologyAddRequest: false,
				communicationTechnologyAddOk: true,
				communicationTechnologyAddMessages: [],
				communicationTechnologies: [
					...state.communicationTechnologies,
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_ADD_ERROR:
			return {
				...state,
				communicationTechnologyAddRequest: false,
				communicationTechnologyAddOk: false,
				communicationTechnologyAddMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_EDIT_START:
			return {
				...state,
				communicationTechnologyEditRequest: true,
				communicationTechnologyEditMessages: [],
				communicationTechnologyEditOk: true
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_EDIT_SUCCESS:
			return {
				...state,
				communicationTechnologyEditRequest: false,
				communicationTechnologyEditOk: true,
				communicationTechnologyEditMessages: [],
				communicationTechnologies: [
					...state.communicationTechnologies.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_EDIT_ERROR:
			return {
				...state,
				communicationTechnologyEditRequest: false,
				communicationTechnologyEditOk: false,
				communicationTechnologyEditMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_DELETE_START:
			return {
				...state,
				communicationTechnologyDeleteRequest: true,
				communicationTechnologyDeleteMessages: [],
				communicationTechnologyDeleteOk: true
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_DELETE_SUCCESS:
			return {
				...state,
				communicationTechnologyDeleteRequest: false,
				communicationTechnologyDeleteOk: true,
				communicationTechnologyDeleteMessages: [],
				communicationTechnologies: [
					...state.communicationTechnologies.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.COMMUNICATIONTECHNOLOGY_DELETE_ERROR:
			return {
				...state,
				communicationTechnologyDeleteRequest: false,
				communicationTechnologyDeleteOk: false,
				communicationTechnologyDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
