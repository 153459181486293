export default {
	BRAND_GETALL_START: 'BRAND_GETALL_START',
	BRAND_GETALL_SUCCESS: 'BRAND_GETALL_SUCCESS',
	BRAND_GETALL_ERROR: 'BRAND_GETALL_ERROR',
	BRAND_GETBYID_START: 'BRAND_GETBYID_START',
	BRAND_GETBYID_SUCCESS: 'BRAND_GETBYID_SUCCESS',
	BRAND_GETBYID_ERROR: 'BRAND_GETBYID_ERROR',
	BRAND_ADD_START: 'BRAND_ADD_START',
	BRAND_ADD_SUCCESS: 'BRAND_ADD_SUCCESS',
	BRAND_ADD_ERROR: 'BRAND_ADD_ERROR',
	BRAND_EDIT_START: 'BRAND_EDIT_START',
	BRAND_EDIT_SUCCESS: 'BRAND_EDIT_SUCCESS',
	BRAND_EDIT_ERROR: 'BRAND_EDIT_ERROR',
	BRAND_DELETE_START: 'BRAND_DELETE_START',
	BRAND_DELETE_SUCCESS: 'BRAND_DELETE_SUCCESS',
	BRAND_DELETE_ERROR: 'BRAND_DELETE_ERROR'
};
