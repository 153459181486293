import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Model,
		path: '/model',
		exact: true,
		name: 'Modelo',
		component: Loadable({
			loader: () => import('~/routes/Model/ModelList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.ModelAdd,
		path: '/model/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Model/ModelAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.ModelEdit,
		path: '/model/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Model/ModelEdit'),
			loading: Loading,
		})
	}
];
