import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	communicationUsesGetAllRequest: false,
	communicationUsesGetAllOk: true,
	communicationUsesGetAllMessages: [],
	communicationUsesGetByIdRequest: false,
	communicationUsesGetByIdOk: true,
	communicationUsesGetByIdMessages: [],
	communicationUsesAddRequest: false,
	communicationUsesAddOk: true,
	communicationUsesAddMessages: [],
	communicationUsesEditRequest: false,
	communicationUsesEditOk: true,
	communicationUsesEditMessages: [],
	communicationUsesDeleteRequest: false,
	communicationUsesDeleteOk: true,
	communicationUsesDeleteMessages: [],
	communicationUses: [],
	communicationUse: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.COMMUNICATIONUSES_GETALL_START:
			return {
				...state,
				USESUsesGetAllRequest: true,
				USESUsesGetAllOk: true,
				communicationUsesGetAllMessages: [],
				communicationUses: initialState.communicationUses
			};
		case actionTypes.COMMUNICATIONUSES_GETALL_SUCCESS:
			return {
				...state,
				communicationUsesGetAllRequest: false,
				communicationUsesGetAllOk: true,
				communicationUsesGetAllMessages: [],
				communicationUses: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMMUNICATIONUSES_GETALL_ERROR:
			return {
				...state,
				communicationUsesGetAllRequest: false,
				communicationUsesGetAllOk: false,
				communicationUsesGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONUSES_GETBYID_START:
			return {
				...state,
				communicationUsesGetByIdRequest: true,
				communicationUsesGetByIdOk: true,
				communicationUsesGetByIdMessages: [],
				communicationUse: null
			};
		case actionTypes.COMMUNICATIONUSES_GETBYID_SUCCESS:
			return {
				...state,
				communicationUsesGetByIdRequest: false,
				communicationUsesGetByIdOk: true,
				communicationUsesGetByIdMessages: [],
				communicationUse: action.payload
			};
		case actionTypes.COMMUNICATIONUSES_GETBYID_ERROR:
			return {
				...state,
				communicationUsesGetByIdRequest: false,
				communicationUsesGetByIdOk: false,
				communicationUsesGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONUSES_ADD_START:
			return {
				...state,
				communicationUsesAddRequest: true,
				communicationUsesAddMessages: [],
				communicationUsesAddOk: true,
			};
		case actionTypes.COMMUNICATIONUSES_ADD_SUCCESS:
			return {
				...state,
				communicationUsesAddRequest: false,
				communicationUsesAddOk: true,
				communicationUsesAddMessages: [],
				communicationUses: [
					...state.communicationUses,
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONUSES_ADD_ERROR:
			return {
				...state,
				communicationUsesAddRequest: false,
				communicationUsesAddOk: false,
				communicationUsesAddMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONUSES_EDIT_START:
			return {
				...state,
				communicationUsesEditRequest: true,
				communicationUsesEditMessages: [],
				communicationUsesEditOk: true
			};
		case actionTypes.COMMUNICATIONUSES_EDIT_SUCCESS:
			return {
				...state,
				communicationUsesEditRequest: false,
				communicationUsesEditOk: true,
				communicationUsesEditMessages: [],
				communicationUses: [
					...state.communicationUses.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONUSES_EDIT_ERROR:
			return {
				...state,
				communicationUsesEditRequest: false,
				communicationUsesEditOk: false,
				communicationUsesEditMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONUSES_DELETE_START:
			return {
				...state,
				communicationUsesDeleteRequest: true,
				communicationUsesDeleteMessages: [],
				communicationUsesDeleteOk: true
			};
		case actionTypes.COMMUNICATIONUSES_DELETE_SUCCESS:
			return {
				...state,
				communicationUsesDeleteRequest: false,
				communicationUsesDeleteOk: true,
				communicationUsesDeleteMessages: [],
				communicationUses: [
					...state.communicationUses.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.COMMUNICATIONUSES_DELETE_ERROR:
			return {
				...state,
				communicationUsesDeleteRequest: false,
				communicationUsesDeleteOk: false,
				communicationUsesDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
