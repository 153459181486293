import { transliterate } from 'transliteration';

const transliterationOptions = {
	ignore: [
		'á', 'Á',
		'à', 'À',
		'ä', 'Ä',
		'å', 'Å',
		'æ', 'Æ',
		'é', 'É',
		'è', 'È',
		'ë', 'Ë',
		'í', 'Í',
		'ì', 'Ì',
		'ï', 'Ï',
		'ó', 'Ó',
		'ò', 'Ò',
		'ö', 'Ö',
		'ø', 'Ø',
		'ô', 'Ô',
		'ú', 'Ú',
		'ü', 'Ü',
		'ù', 'Ù',
		'ñ', 'Ñ',
		'ç', 'Ç'
	]
};

export const romanize = text => (
	transliterate(text, transliterationOptions)
);

/**
 * Checks that an element has a non-empty `name` and `value` property.
 * @param  {Element} element  the element to check
 * @return {Bool}             true if the element is an input, false if not
 */
export const isValidElement = element => element.name && element.value;

/**
 * Checks if an element’s value can be saved (e.g. not an unselected checkbox).
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the value should be added, false if not
 */
export const isValidValue = element => (
	(!['checkbox', 'radio'].includes(element.type) || element.checked)
);

/**
 * Checks if an input is a checkbox, because checkboxes allow multiple values.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a checkbox, false if not
 */
export const isCheckbox = element => element.type === 'checkbox';

/**
 * Checks if an input is a `select` with the `multiple` attribute.
 * @param  {Element} element  the element to check
 * @return {Boolean}          true if the element is a multiselect, false if not
 */
export const isMultiSelect = element => element.options && element.multiple;

/**
 * Retrieves the selected options from a multi-select as an array.
 * @param  {HTMLOptionsCollection} options  the options for the select
 * @return {Array}                          an array of selected option values
 */
const getSelectValues = options => [].reduce.call(options, (values, option) => {
	return option.selected ? values.concat(option.value) : values;
}, []);


/**
 * Retrieves input data from a form and returns it as a JSON object.
 * @param  {HTMLFormControlsCollection} elements  the form elements
 * @return {Object}                               form data as an object literal
 */
export const formToJSON = elements => [].reduce.call(elements, (data, element) => {
	// Make sure the element has the required properties and should be added.
	if (isValidElement(element) && isValidValue(element)) {
		/*
		 * Some fields allow for more than one value, so we need to check if this
		 * is one of those fields and, if so, store the values as an array.
		 */
		if (isCheckbox(element)) {
			data[element.name] = (data[element.name] || []).concat(element.value);
		} else if (isMultiSelect(element)) {
			data[element.name] = getSelectValues(element);
		} else {
			data[element.name] = element.value;
		}
	}

	return data;
}, {});

export const isRequired = (defaultValue = undefined) => value => (value !== defaultValue ? undefined : 'Requerido');
export const isNumber = value => (value !== undefined && Number.isNaN(value) ? 'Debe ser un número' : undefined);
export const isInteger = value => (value === undefined || /^((-[1-9]+)|([0-9]+))$/.test(value) ? undefined : 'Debe de ser un entero');
export const isDate = value => (value === undefined || new Date(value).toString() !== 'Invalid Date' ? undefined : 'Fecha no válida');
export const isEmail = value => (value === undefined || /(.+)@(.+)\.(.+)/.test(value) ? undefined : 'Email no válido');


export function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const haveMaxLength = maxLength => value => (
	(value === undefined || value.length <= maxLength) ? undefined : `La longitud máxima es ${maxLength} caracteres`
);
export const haveMinLength = minLength => value => (
	(value !== undefined && value.length >= minLength) ? undefined : `La longitud mínima es ${minLength} caracteres`
);
export const haveMaxValue = maxValue => value => (
	(value === undefined || value <= maxValue) ? undefined : `El máximo valor permitido es ${maxValue}`
);
export const haveMinValue = minValue => value => (
	(value === undefined || value >= minValue) ? undefined : `El mínimo valor permitido es ${minValue}`
);
export const composeValidators = (...validators) => value => (
	validators.reduce((error, validator) => error || validator(value), undefined)
);
