import EmployeeActionTypes from './EmployeeActionTypes';
import CommonActionType from './CommonActionType';
import CompanyActionTypes from './CompanyActionTypes';
import BranchActionTypes from './BranchActionTypes';
import DepartmentActionTypes from './DepartmentActionTypes';
import BrandActionTypes from './BrandActionTypes';
import ModelActionTypes from './ModelActionTypes';
import SupplierActionTypes from './SupplierActionTypes';
import HardwareActionTypes from './HardwareActionTypes';
import CommunicationActionTypes from './CommunicationActionTypes';
import HardwareTypeActionTypes from './HardwareTypeActionTypes';
import CommunicationTechnologyActionTypes from './CommunicationTechnologyActionTypes';
import CommunicationUsesActionTypes from './CommunicationUsesActionTypes';
import ServiceActionTypes from './ServiceActionTypes';
import MovementTypeActionTypes from './MovementTypeActionTypes';
import MovementActionTypes from './MovementActionTypes';
import CommunicationTypeActionTypes from './CommunicationTypeActionTypes';
import SocietyActionTypes from './SocietyActionTypes';

export default {
	...EmployeeActionTypes,
	...CommonActionType,
	...CompanyActionTypes,
	...BranchActionTypes,
	...DepartmentActionTypes,
	...BrandActionTypes,
	...ModelActionTypes,
	...SupplierActionTypes,
	...HardwareActionTypes,
	...CommunicationActionTypes,
	...HardwareTypeActionTypes,
	...CommunicationTechnologyActionTypes,
	...CommunicationUsesActionTypes,
	...ServiceActionTypes,
	...MovementTypeActionTypes,
	...MovementActionTypes,
	...CommunicationTypeActionTypes,
	...SocietyActionTypes
};
