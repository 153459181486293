import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Department,
		path: '/department',
		exact: true,
		name: 'Departamento',
		component: Loadable({
			loader: () => import('~/routes/Department/DepartmentList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.DepartmentAdd,
		path: '/department/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Department/DepartmentAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.DepartmentEdit,
		path: '/department/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Department/DepartmentEdit'),
			loading: Loading,
		})
	}
];
