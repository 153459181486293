import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

// COMPONENTS
import Layout from '~/components/Layout';
import Login from '~/routes/Login/Login';

import 'font-awesome/css/font-awesome.min.css';
import '../assets/scss/_App.scss';
import Error404 from './Shared/PagesError/Error404';
import Error500 from './Shared/PagesError/Error500';
import history from '../helpers/history';
import PrivateRoute from './PrivateRoute';

const App = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route path="/login" name="Login Page" component={Login} />
				<Route exact path="/404" name="Page 404" component={Error404} />
				<Route exact path="/500" name="Page 500" component={Error500} />
				<PrivateRoute path="/" name="Home" component={Layout} />
			</Switch>
		</Router>
	);
};

export default App;
