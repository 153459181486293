import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const employee = localStorage.getItem('employee') && JSON.parse(localStorage.getItem('employee'));

const initialState = {
	loginRequest: false,
	loginOK: true,
	loginMessages: [],
	employee
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			localStorage.removeItem('employee');
			return initialState;
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_START:
			return {
				...state,
				loginRequest: true,
				loginMessages: [],
				loginOK: true,
				employee: null
			};
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_SUCCESS:
			localStorage.setItem('employee', JSON.stringify(action.employee));
			return {
				...state,
				loginRequest: false,
				loginOK: true,
				loginMessages: [],
				employee: deepCopy(action.employee)
			};
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_ERROR:
			return {
				...state,
				loginRequest: false,
				loginOK: false,
				loginMessages: deepCopy(action.messages)
			};
		default:
			return state;
	}
};
