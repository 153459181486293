import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	hardwareGetAllRequest: false,
	hardwareGetAllOk: true,
	hardwareGetAllMessages: [],
	hardwareGetByIdRequest: false,
	hardwareGetByIdOk: true,
	hardwareGetByIdMessages: [],
	hardwareAddRequest: false,
	hardwareAddOk: true,
	hardwareAddMessages: [],
	hardwareEditRequest: false,
	hardwareEditOk: true,
	hardwareEditMessages: [],
	hardwareDeleteRequest: false,
	hardwareDeleteOk: true,
	hardwareDeleteMessages: [],
	hardwareGetPinpadsByBranchRequest: false,
	hardwareGetPinpadsByBranchOk: true,
	hardwareGetPinpadsByBranchMessages: [],
	hardwareGetPrintersByBranchRequest: false,
	hardwareGetPrintersByBranchOk: true,
	hardwareGetPrintersByBranchMessages: [],
	hardwareSearchRequest: false,
	hardwareSearchOk: true,
	hardwareSearchMessages: [],
	hardwares: [],
	hardware: null,
	printers: [],
	pinpads: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.HARDWARE_GETALL_START:
			return {
				...state,
				hardwareGetAllRequest: true,
				hardwareGetAllOk: true,
				hardwareGetAllMessages: [],
				hardwares: initialState.hardwares
			};
		case actionTypes.HARDWARE_GETALL_SUCCESS:
			return {
				...state,
				hardwareGetAllRequest: false,
				hardwareGetAllOk: true,
				hardwareGetAllMessages: [],
				hardwares: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.HARDWARE_GETALL_ERROR:
			return {
				...state,
				hardwareGetAllRequest: false,
				hardwareGetAllOk: false,
				hardwareGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_GETBYID_START:
			return {
				...state,
				hardwareGetByIdRequest: true,
				hardwareGetByIdOk: true,
				hardwareGetByIdMessages: [],
				hardware: null
			};
		case actionTypes.HARDWARE_GETBYID_SUCCESS:
			return {
				...state,
				hardwareGetByIdRequest: false,
				hardwareGetByIdOk: true,
				hardwareGetByIdMessages: [],
				hardware: action.payload
			};
		case actionTypes.HARDWARE_GETBYID_ERROR:
			return {
				...state,
				hardwareGetByIdRequest: false,
				hardwareGetByIdOk: false,
				hardwareGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_ADD_START:
			return {
				...state,
				hardwareAddRequest: true,
				hardwareAddMessages: [],
				hardwareAddOk: true,
			};
		case actionTypes.HARDWARE_ADD_SUCCESS:
			return {
				...state,
				hardwareAddRequest: false,
				hardwareAddOk: true,
				hardwareAddMessages: [],
				hardwares: [
					action.payload
				]
			};
		case actionTypes.HARDWARE_ADD_ERROR:
			return {
				...state,
				hardwareAddRequest: false,
				hardwareAddOk: false,
				hardwareAddMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_EDIT_START:
			return {
				...state,
				hardwareEditRequest: true,
				hardwareEditMessages: [],
				hardwareEditOk: true
			};
		case actionTypes.HARDWARE_EDIT_SUCCESS:
			return {
				...state,
				hardwareEditRequest: false,
				hardwareEditOk: true,
				hardwareEditMessages: [],
				hardwares: [
					...state.hardwares.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.HARDWARE_EDIT_ERROR:
			return {
				...state,
				hardwareEditRequest: false,
				hardwareEditOk: false,
				hardwareEditMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_DELETE_START:
			return {
				...state,
				hardwareDeleteRequest: true,
				hardwareDeleteMessages: [],
				hardwareDeleteOk: true
			};
		case actionTypes.HARDWARE_DELETE_SUCCESS:
			return {
				...state,
				hardwareDeleteRequest: false,
				hardwareDeleteOk: true,
				hardwareDeleteMessages: [],
				hardwares: [
					...state.hardwares.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.HARDWARE_DELETE_ERROR:
			return {
				...state,
				hardwareDeleteRequest: false,
				hardwareDeleteOk: false,
				hardwareDeleteMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_GET_PINPADS_BY_BRANCH_START:
			return {
				...state,
				hardwareGetPinpadsByBranchRequest: true,
				hardwareGetPinpadsByBranchOk: true,
				hardwareGetPinpadsByBranchMessages: []
			};
		case actionTypes.HARDWARE_GET_PINPADS_BY_BRANCH_SUCCESS:
			return {
				...state,
				hardwareGetPinpadsByBranchRequest: false,
				hardwareGetPinpadsByBranchOk: true,
				hardwareGetPinpadsByBranchMessages: [],
				pinpads: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.HARDWARE_GET_PINPADS_BY_BRANCH_ERROR:
			return {
				...state,
				hardwareGetPinpadsByBranchRequest: false,
				hardwareGetPinpadsByBranchOk: false,
				hardwareGetPinpadsByBranchMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_GET_PRINTERS_BY_BRANCH_START:
			return {
				...state,
				hardwareGetPrintersByBranchRequest: true,
				hardwareGetPrintersByBranchOk: true,
				hardwareGetPrintersByBranchMessages: []
			};
		case actionTypes.HARDWARE_GET_PRINTERS_BY_BRANCH_SUCCESS:
			return {
				...state,
				hardwareGetPrintersByBranchRequest: false,
				hardwareGetPrintersByBranchOk: true,
				hardwareGetPrintersByBranchMessages: [],
				printers: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.HARDWARE_GET_PRINTERS_BY_BRANCH_ERROR:
			return {
				...state,
				hardwareGetPrintersByBranchRequest: false,
				hardwareGetPrintersByBranchOk: false,
				hardwareGetPrintersByBranchMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_FILTER:
			return {
				...state,
				hardwares: [
					...state.hardwares.filter(x => x.companyId === action.payload.companyId)
				]
			};
		case actionTypes.HARDWARE_SEARCH_START:
			return {
				...state,
				hardwareSearchRequest: true,
				hardwareSearchMessages: [],
				hardwareSearchOk: true,
				hardwares: []
			};
		case actionTypes.HARDWARE_SEARCH_SUCCESS:
			return {
				...state,
				hardwareSearchRequest: false,
				hardwareSearchOk: true,
				hardwareSearchMessages: [],
				hardwares: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.HARDWARE_SEARCH_ERROR:
			return {
				...state,
				hardwareSearchRequest: false,
				hardwareSearchOk: false,
				hardwareSearchMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARE_SHOW_LOW:
			return {
				...state,
				hardwares: [
					...state.hardwares.filter(x => x.low === null)
				]
			};
		case actionTypes.HARDWARE_CLEAR:
			return {
				...state,
				hardwares: []
			};
		default:
			return state;
	}
};
