export default {
	SUPPLIER_GETALL_START: 'SUPPLIER_GETALL_START',
	SUPPLIER_GETALL_SUCCESS: 'SUPPLIER_GETALL_SUCCESS',
	SUPPLIER_GETALL_ERROR: 'SUPPLIER_GETALL_ERROR',
	SUPPLIER_GETBYID_START: 'SUPPLIER_GETBYID_START',
	SUPPLIER_GETBYID_SUCCESS: 'SUPPLIER_GETBYID_SUCCESS',
	SUPPLIER_GETBYID_ERROR: 'SUPPLIER_GETBYID_ERROR',
	SUPPLIER_ADD_START: 'SUPPLIER_ADD_START',
	SUPPLIER_ADD_SUCCESS: 'SUPPLIER_ADD_SUCCESS',
	SUPPLIER_ADD_ERROR: 'SUPPLIER_ADD_ERROR',
	SUPPLIER_EDIT_START: 'SUPPLIER_EDIT_START',
	SUPPLIER_EDIT_SUCCESS: 'SUPPLIER_EDIT_SUCCESS',
	SUPPLIER_EDIT_ERROR: 'SUPPLIER_EDIT_ERROR',
	SUPPLIER_DELETE_START: 'SUPPLIER_DELETE_START',
	SUPPLIER_DELETE_SUCCESS: 'SUPPLIER_DELETE_SUCCESS',
	SUPPLIER_DELETE_ERROR: 'SUPPLIER_DELETE_ERROR'
};
