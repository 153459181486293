export default {
	COMMUNICATION_GETALL_START: 'COMMUNICATION_GETALL_START',
	COMMUNICATION_GETALL_SUCCESS: 'COMMUNICATION_GETALL_SUCCESS',
	COMMUNICATION_GETALL_ERROR: 'COMMUNICATION_GETALL_ERROR',
	COMMUNICATION_GETBYID_START: 'COMMUNICATION_GETBYID_START',
	COMMUNICATION_GETBYID_SUCCESS: 'COMMUNICATION_GETBYID_SUCCESS',
	COMMUNICATION_GETBYID_ERROR: 'COMMUNICATION_GETBYID_ERROR',
	COMMUNICATION_ADD_START: 'COMMUNICATION_ADD_START',
	COMMUNICATION_ADD_SUCCESS: 'COMMUNICATION_ADD_SUCCESS',
	COMMUNICATION_ADD_ERROR: 'COMMUNICATION_ADD_ERROR',
	COMMUNICATION_EDIT_START: 'COMMUNICATION_EDIT_START',
	COMMUNICATION_EDIT_SUCCESS: 'COMMUNICATION_EDIT_SUCCESS',
	COMMUNICATION_EDIT_ERROR: 'COMMUNICATION_EDIT_ERROR',
	COMMUNICATION_DELETE_START: 'COMMUNICATION_DELETE_START',
	COMMUNICATION_DELETE_SUCCESS: 'COMMUNICATION_DELETE_SUCCESS',
	COMMUNICATION_DELETE_ERROR: 'COMMUNICATION_DELETE_ERROR',
	COMMUNICATION_SEARCH_START: 'COMMUNICATION_SEARCH_START',
	COMMUNICATION_SEARCH_SUCCESS: 'COMMUNICATION_SEARCH_SUCCESS',
	COMMUNICATION_SEARCH_ERROR: 'COMMUNICATION_SEARCH_ERROR',
	COMMUNICATION_SHOW_LOW: 'COMMUNICATION_SHOW_LOW'
};
