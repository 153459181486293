import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Communication,
		path: '/communication',
		exact: true,
		name: 'Comunicación',
		component: Loadable({
			loader: () => import('~/routes/Communication/CommunicationList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationAdd,
		path: '/communication/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Communication/CommunicationAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationEdit,
		path: '/communication/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Communication/CommunicationEdit'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationCopy,
		path: '/communication/Copy/:id',
		exact: true,
		name: 'Copiar',
		component: Loadable({
			loader: () => import('~/routes/Communication/CommunicationCopy'),
			loading: Loading,
		})
	}
];
