import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	departmentGetAllRequest: false,
	departmentGetAllOk: true,
	departmentGetAllMessages: [],
	departmentGetByIdRequest: false,
	departmentGetByIdOk: true,
	departmentGetByIdMessages: [],
	departmentAddRequest: false,
	departmentAddOk: true,
	departmentAddMessages: [],
	departmentEditRequest: false,
	departmentEditOk: true,
	departmentEditMessages: [],
	departmentDeleteRequest: false,
	departmentDeleteOk: true,
	departmentDeleteMessages: [],
	departments: [],
	department: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.DEPARTMENT_GETALL_START:
			return {
				...state,
				departmentGetAllRequest: true,
				departmentGetAllOk: true,
				departmentGetAllMessages: [],
				departments: initialState.departments
			};
		case actionTypes.DEPARTMENT_GETALL_SUCCESS:
			return {
				...state,
				departmentGetAllRequest: false,
				departmentGetAllOk: true,
				departmentGetAllMessages: [],
				departments: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.DEPARTMENT_GETALL_ERROR:
			return {
				...state,
				departmentGetAllRequest: false,
				departmentGetAllOk: false,
				departmentGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.DEPARTMENT_GETBYID_START:
			return {
				...state,
				departmentGetByIdRequest: true,
				departmentGetByIdOk: true,
				departmentGetByIdMessages: [],
				department: null
			};
		case actionTypes.DEPARTMENT_GETBYID_SUCCESS:
			return {
				...state,
				departmentGetByIdRequest: false,
				departmentGetByIdOk: true,
				departmentGetByIdMessages: [],
				department: action.payload
			};
		case actionTypes.DEPARTMENT_GETBYID_ERROR:
			return {
				...state,
				departmentGetByIdRequest: false,
				departmentGetByIdOk: false,
				departmentGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.DEPARTMENT_ADD_START:
			return {
				...state,
				departmentAddRequest: true,
				departmentAddMessages: [],
				departmentAddOk: true,
			};
		case actionTypes.DEPARTMENT_ADD_SUCCESS:
			return {
				...state,
				departmentAddRequest: false,
				departmentAddOk: true,
				departmentAddMessages: [],
				departments: [
					...state.departments,
					action.payload
				]
			};
		case actionTypes.DEPARTMENT_ADD_ERROR:
			return {
				...state,
				departmentAddRequest: false,
				departmentAddOk: false,
				departmentAddMessages: deepCopy(action.payload)
			};
		case actionTypes.DEPARTMENT_EDIT_START:
			return {
				...state,
				departmentEditRequest: true,
				departmentEditMessages: [],
				departmentEditOk: true
			};
		case actionTypes.DEPARTMENT_EDIT_SUCCESS:
			return {
				...state,
				departmentEditRequest: false,
				departmentEditOk: true,
				departmentEditMessages: [],
				departments: [
					...state.departments.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.DEPARTMENT_EDIT_ERROR:
			return {
				...state,
				departmentEditRequest: false,
				departmentEditOk: false,
				departmentEditMessages: deepCopy(action.payload)
			};
		case actionTypes.DEPARTMENT_DELETE_START:
			return {
				...state,
				departmentDeleteRequest: true,
				departmentDeleteMessages: [],
				departmentDeleteOk: true
			};
		case actionTypes.DEPARTMENT_DELETE_SUCCESS:
			return {
				...state,
				departmentDeleteRequest: false,
				departmentDeleteOk: true,
				departmentDeleteMessages: [],
				departments: [
					...state.departments.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.DEPARTMENT_DELETE_ERROR:
			return {
				...state,
				departmentDeleteRequest: false,
				departmentDeleteOk: false,
				departmentDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
