import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Service,
		path: '/service',
		exact: true,
		name: 'Servicio',
		component: Loadable({
			loader: () => import('~/routes/Service/ServiceList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.ServiceAdd,
		path: '/service/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Service/ServiceAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.ServiceEdit,
		path: '/service/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Service/ServiceEdit'),
			loading: Loading,
		})
	}
];
