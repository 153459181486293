import React from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';

import { romanize } from '~/helpers/forms';
import FormField from '~/components/Shared/FormField';

const InputFormField = FormField((input, meta, label, id, type, attributes) => {
	const {
		onChange,
		placeholder,
		disabled,
		onBlur,
		onKeyDown,
		step,
		min,
		max,
		autofocus,
		className,
		autoComplete,
		pattern,
		onlyDigits
	} = attributes;

	const inputStep = step ? (step).toString() : '1';

	const setOnChange = (e) => {
		// TODO: QUITAR CUANDO SE PONGA NAV 2018
		if (type === 'text') {
			e.currentTarget.value = romanize(e.currentTarget.value);
		}

		if (onlyDigits) {
			const newValue = e.currentTarget.value.replace(/\D/g, '').trim();
			e.currentTarget.value = newValue;
		}

		input.onChange(e);
		if (onChange) {
			onChange(e);
		}
	};

	const handleOnBlur = (e) => {
		switch (type) {
			case 'radio':
			case 'checkbox':
			case 'file':
				break;
			default:
				let tempValue = e.currentTarget.value;

				if (type === 'number' && inputStep) {
					// SI EL STEP NO ES DECIMAL, EL VALOR SERÁ ENTERO
					if (inputStep && !inputStep.includes('.')) {
						tempValue = Math.floor(e.currentTarget.value);
						const rest = tempValue % inputStep;
						if (inputStep && rest > 0) {
							e.currentTarget.value = tempValue - rest;
							input.onChange(e);
							break;
						}

						e.currentTarget.value = tempValue;
						input.onChange(e);
						break;
					}

					if (inputStep && inputStep.includes('.')) {
						tempValue = !Number.isNaN(Number(tempValue)) ? Number(tempValue).toFixed(2) : tempValue;
					}
				}

				if (type === 'text') {
					tempValue = e.currentTarget.value.replace(/\s{2,}/ig, ' ').trim();
				}

				e.currentTarget.value = tempValue;
				input.onChange(e);
				break;
		}

		input.onBlur(e);
		if (onBlur) {
			onBlur(e);
		}
	};

	return (
		<Input
			id={id}
			className={classNames(className)}
			autoComplete={autoComplete}
			placeholder={placeholder}
			disabled={disabled}
			name={input.name}
			value={input.value}
			checked={!!input.checked}
			onChange={setOnChange}
			// onKeyDown={handleOnKeyDown}
			onBlur={handleOnBlur}
			type={type}
			step={inputStep}
			min={min}
			max={max}
			autoFocus={autofocus}
			pattern={pattern}
		/>
	);
});

export default React.memo(InputFormField);
