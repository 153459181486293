import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.MovementHardware,
		path: '/hardware/movement/:id',
		exact: true,
		name: 'Movimiento',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.MovementHardwareAdd,
		path: '/hardware/movement/:id/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.MovementHardwareEdit,
		path: '/hardware/movement/:id/Edit/:movementId',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementEdit'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.MovementCommunication,
		path: '/communication/movement/:id',
		exact: true,
		name: 'Movimiento',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.MovementCommunicationAdd,
		path: '/communication/movement/:id/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.MovementCommunicationEdit,
		path: '/communication/movement/:id/Edit/:movementId',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Movement/MovementEdit'),
			loading: Loading,
		})
	}
];
