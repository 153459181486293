import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form as FormContainer, Field } from 'react-final-form';

import {
	Button,
	Card,
	CardBody,
	CardGroup,
	Col,
	Container,
	Row,
	Form
} from 'reactstrap';

import logo from '~/assets/img/brand/logo.svg';
import MessagePropType from '~/constants/PropTypes/MessagePropType';
import InputFormField from '~/components/Shared/InputFormField/InputFormField';

import { isRequired } from '~/helpers/forms';
import { GCSwal } from '~/constants/Types/SwalType';
import { history } from '~/helpers';

class Login extends PureComponent {
	static propTypes = {
		onLogIn: PropTypes.func.isRequired,
		loginRequest: PropTypes.bool.isRequired,
		loginOK: PropTypes.bool.isRequired,
		loginMessages: PropTypes.arrayOf(MessagePropType).isRequired,
	}

	constructor(props) {
		super(props);
		this.state = {
			username: undefined,
			password: undefined
		};
	}

	// async componentDidMount() {
	// 	SetDocumentTitle('Login');
	// }

	handleSubmit = async ({ username, password }) => {
		const {
			onLogIn,
			loginRequest
		} = this.props;
		if (!loginRequest) {
			await onLogIn(username, password);
			const {
				loginOK,
				loginMessages
			} = this.props;
			if (loginOK) {
				history.push('/Hardware');
			} else {
				await GCSwal('Error', loginMessages[0].message);
			}
		}
	}

	render() {
		const {
			username,
			password
		} = this.state;
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col sm="8" md="6" lg="4" xl="4">
							<CardGroup>
								<Card>
									<CardBody>
										<img className="mx-auto d-block" src="favicon.png" alt="Centauro Rent a Car" width="60" />
										<h1><img src={logo} alt="Centauro Rent a Car" /></h1>
										<p className="text-muted text-center">Ingrese a su cuenta</p>
										<FormContainer
											onSubmit={this.handleSubmit}
											initialValues={{ username, password }}
											subscription={{ submitting: true, pristine: true, invalid: true }}
										>
											{
												({
													handleSubmit,
													submitting,
													pristine,
													form,
													invalid
												}) => {
													this.resetForm = form.reset;
													return (
														<Form onSubmit={handleSubmit} disabled={submitting} autoComplete="off">
															<Field
																id="username"
																name="username"
																placeholder="Usuario"
																component={InputFormField}
																validate={isRequired()}
																autofocus
															/>
															<Field
																id="password"
																name="password"
																placeholder="Contraseña"
																type="password"
																component={InputFormField}
																validate={isRequired()}
															/>
															<div className="text-right">
																<Button color="primary" type="submit" disabled={invalid || pristine || submitting}>
																	Iniciar sesión {submitting && <i className="fa fa-fw fa-pulse fa-spinner" />}
																</Button>
															</div>
														</Form>
													);
												}
											}
										</FormContainer>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Login;
