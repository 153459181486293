export default {
	HARDWARETYPE_GETALL_START: 'HARDWARETYPE_GETALL_START',
	HARDWARETYPE_GETALL_SUCCESS: 'HARDWARETYPE_GETALL_SUCCESS',
	HARDWARETYPE_GETALL_ERROR: 'HARDWARETYPE_GETALL_ERROR',
	HARDWARETYPE_GETBYID_START: 'HARDWARETYPE_GETBYID_START',
	HARDWARETYPE_GETBYID_SUCCESS: 'HARDWARETYPE_GETBYID_SUCCESS',
	HARDWARETYPE_GETBYID_ERROR: 'HARDWARETYPE_GETBYID_ERROR',
	HARDWARETYPE_ADD_START: 'HARDWARETYPE_ADD_START',
	HARDWARETYPE_ADD_SUCCESS: 'HARDWARETYPE_ADD_SUCCESS',
	HARDWARETYPE_ADD_ERROR: 'HARDWARETYPE_ADD_ERROR',
	HARDWARETYPE_EDIT_START: 'HARDWARETYPE_EDIT_START',
	HARDWARETYPE_EDIT_SUCCESS: 'HARDWARETYPE_EDIT_SUCCESS',
	HARDWARETYPE_EDIT_ERROR: 'HARDWARETYPE_EDIT_ERROR',
	HARDWARETYPE_DELETE_START: 'HARDWARETYPE_DELETE_START',
	HARDWARETYPE_DELETE_SUCCESS: 'HARDWARETYPE_DELETE_SUCCESS',
	HARDWARETYPE_DELETE_ERROR: 'HARDWARETYPE_DELETE_ERROR'
};
