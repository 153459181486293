import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	serviceGetAllRequest: false,
	serviceGetAllOk: true,
	serviceGetAllMessages: [],
	serviceGetByIdRequest: false,
	serviceGetByIdOk: true,
	serviceGetByIdMessages: [],
	serviceAddRequest: false,
	serviceAddOk: true,
	serviceAddMessages: [],
	serviceEditRequest: false,
	serviceEditOk: true,
	serviceEditMessages: [],
	serviceDeleteRequest: false,
	serviceDeleteOk: true,
	serviceDeleteMessages: [],
	services: [],
	service: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.SERVICE_GETALL_START:
			return {
				...state,
				serviceGetAllRequest: true,
				serviceGetAllOk: true,
				serviceGetAllMessages: [],
				services: initialState.services
			};
		case actionTypes.SERVICE_GETALL_SUCCESS:
			return {
				...state,
				serviceGetAllRequest: false,
				serviceGetAllOk: true,
				serviceGetAllMessages: [],
				services: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.SERVICE_GETALL_ERROR:
			return {
				...state,
				serviceGetAllRequest: false,
				serviceGetAllOk: false,
				serviceGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.SERVICE_GETBYID_START:
			return {
				...state,
				serviceGetByIdRequest: true,
				serviceGetByIdOk: true,
				serviceGetByIdMessages: [],
				service: null
			};
		case actionTypes.SERVICE_GETBYID_SUCCESS:
			return {
				...state,
				serviceGetByIdRequest: false,
				serviceGetByIdOk: true,
				serviceGetByIdMessages: [],
				service: action.payload
			};
		case actionTypes.SERVICE_GETBYID_ERROR:
			return {
				...state,
				serviceGetByIdRequest: false,
				serviceGetByIdOk: false,
				serviceGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.SERVICE_ADD_START:
			return {
				...state,
				serviceAddRequest: true,
				serviceAddMessages: [],
				serviceAddOk: true,
			};
		case actionTypes.SERVICE_ADD_SUCCESS:
			return {
				...state,
				serviceAddRequest: false,
				serviceAddOk: true,
				serviceAddMessages: [],
				services: [
					...state.services,
					action.payload
				]
			};
		case actionTypes.SERVICE_ADD_ERROR:
			return {
				...state,
				serviceAddRequest: false,
				serviceAddOk: false,
				serviceAddMessages: deepCopy(action.payload)
			};
		case actionTypes.SERVICE_EDIT_START:
			return {
				...state,
				serviceEditRequest: true,
				serviceEditMessages: [],
				serviceEditOk: true
			};
		case actionTypes.SERVICE_EDIT_SUCCESS:
			return {
				...state,
				serviceEditRequest: false,
				serviceEditOk: true,
				serviceEditMessages: [],
				services: [
					...state.services.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.SERVICE_EDIT_ERROR:
			return {
				...state,
				serviceEditRequest: false,
				serviceEditOk: false,
				serviceEditMessages: deepCopy(action.payload)
			};
		case actionTypes.SERVICE_DELETE_START:
			return {
				...state,
				serviceDeleteRequest: true,
				serviceDeleteMessages: [],
				serviceDeleteOk: true
			};
		case actionTypes.SERVICE_DELETE_SUCCESS:
			return {
				...state,
				serviceDeleteRequest: false,
				serviceDeleteOk: true,
				serviceDeleteMessages: [],
				services: [
					...state.services.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.SERVICE_DELETE_ERROR:
			return {
				...state,
				serviceDeleteRequest: false,
				serviceDeleteOk: false,
				serviceDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
