import React from 'react';

import FullPageLoader from '~/components/Shared/FullPageLoader';

const Loading = (props) => {
	const { error } = props;
	if (error) {
		console.error(error);
	}
	return <FullPageLoader isVisible />;
};

export default Loading;
