import API_URL_BASE from '~/constants/EndPoints/ApiEndPoint';
import { getOnlyDateFromJsDate, history } from '~/helpers';

const getItemFormData = (formData, name, data) => {
	if (data === null || data === undefined || data === '') {
		return;
	}
	switch (typeof data) {
		case 'object':
			switch (data.constructor.name) {
				case 'Object':
					Object.keys(data).forEach((key) => {
						getItemFormData(formData, `${name}.${key}`, data[key]);
					});
					break;
				case 'FileList':
					for (let i = 0, { length } = data; i < length; i += 1) {
						formData.append(`${name}[${i}]`, data.item(i));
					}
					break;
				case 'Date':
					formData.append(name, getOnlyDateFromJsDate(data));
					break;
				case 'Array':
					for (let i = 0, { length } = data; i < length; i += 1) {
						getItemFormData(formData, `${name}[${i}]`, data[i]);
					}
					break;
				default:
					formData.append(name, data);
					break;
			}
			break;
		default:
			formData.append(name, data);
			break;
	}
};

export const getFormData = (data) => {
	const formData = new FormData();
	if (data) {
		Object.keys(data).forEach((key) => {
			getItemFormData(formData, key, data[key]);
		});
	}
	return formData;
};

export const getQueryString = (formData) => {
	const queryStringArray = [];
	formData.forEach((value, key) => {
		queryStringArray.push(`${key}=${value}`);
	});
	const queryString = queryStringArray.length ? `?${queryStringArray.join('&')}` : '';
	return queryString;
};

const getHeaders = (method) => {
	const headers = new Headers();
	const employee = localStorage.getItem('employee') && JSON.parse(localStorage.getItem('employee'));
	const token = (employee && employee.token) || null;

	if (token) {
		headers.append('Authorization', "Bearer " + token);
	}

	switch (method) {
		case 'GET':
		case 'HEAD':
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			break;
		default:
			break;
	}
	return headers;
};

const makeRequest = async (method, endpoint, data) => {
	let finalEndPoint = endpoint.slice();
	let body;

	switch (method.toUpperCase()) {
		case 'GET':
		case 'HEAD':
			finalEndPoint = `${finalEndPoint}${getQueryString(getFormData(data))}`;
			break;
		default:
			body = getFormData(data);
			break;
	}

	try {
		const response = await fetch(`${API_URL_BASE}${finalEndPoint}`, {
			method,
			headers: getHeaders(),
			body
		});

		if (response.status === 401 && window.location.pathname.indexOf('/login') === -1) {
			history.push('/login');
			throw new Error('Unauthorized');
		}

		return await response.json();
	} catch (error) {
		return Promise.reject();
	}
};

const api = {
	/**
	 * Request GET type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	get: (endpoint, data) => makeRequest('GET', endpoint, data),

	/**
	 * Request POST type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	post: (endpoint, data) => makeRequest('POST', endpoint, data),

	/**
	 * Request PUT type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	put: (endpoint, data) => makeRequest('PUT', endpoint, data),

	/**
	 * Request DELETE type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	delete: (endpoint, data) => makeRequest('DELETE', endpoint, data),

	/**
	 * Request HEAD type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	head: (endpoint, data) => makeRequest('HEAD', endpoint, data)
};

export default api;
