import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Container } from 'reactstrap';
import {
	AppBreadcrumb,
	AppHeader,
	AppSidebar,
	AppSidebarForm,
	AppSidebarHeader,
	AppSidebarMinimizer,
	AppSidebarNav,
} from '@coreui/react';
import classnames from 'classnames';
import navigation from '~/config/nav.config';
import routes from '~/config/routes/';
import Header from './Header';

import FullPageLoader from '~/components/Shared/FullPageLoader';
import { EmployeeProvider } from '~/constants/Contexts/EmployeeContext';
import { IsDevEnvironment } from '~/helpers';

import './Layout.scss';
import EmployeePropType from '~/constants/PropTypes/EmployeePropType';

class Layout extends React.PureComponent {
	static propTypes = {
		requestcount: PropTypes.number.isRequired,
		loaderisvisible: PropTypes.string.isRequired,
		message: PropTypes.string,
		employee: EmployeePropType.isRequired,
		icon: PropTypes.string,
		match: ReactRouterPropTypes.match.isRequired,
		location: ReactRouterPropTypes.location.isRequired
	}

	static defaultProps = {
		message: null,
		feedbackopen: "false",
		icon: null,
	}

	constructor(props) {
		super(props);

		this.AppPermissions = null;
		this.state = {
			contentReady: false
		};

		this.Interval = null;
	}

	componentDidMount() {
		this.setState({ contentReady: true });
	}

	render() {
		const {
			employee,
			loaderisvisible,
			message,
			icon
		} = this.props;
		const { contentReady } = this.state;

		if (!contentReady) {
			return null;
		}

		const nav = { items: [] };
		navigation.items.forEach((item) => {
			const newItem = { name: item.name, icon: item.icon };
			if (item.children) {
				item.children.forEach((child) => {
					newItem.children = newItem.children || [];
					newItem.children.push({ ...child });
				});
			} else {
				newItem.url = item.url.slice();
			}
			nav.items.push(newItem);
		});

		// TODO:modificar el component AppBreadcrumb para mostrar parámetro configurados en la ruta
		return (
			<>
				<div className={classnames('app', {
					'dev-environment': IsDevEnvironment()
				})}
				>
					<FullPageLoader isVisible={loaderisvisible == "true" ? true : false} message={message} icon={icon} />
					<EmployeeProvider value={employee}>
						<AppHeader fixed>
							<Header {...this.props} />
						</AppHeader>
						<div className="app-body">
							<AppSidebar fixed display="lg">
								<AppSidebarHeader />
								<AppSidebarForm />
								<AppSidebarNav navConfig={nav} {...this.props} />
								<AppSidebarMinimizer data-toggle="sidebar{-sm|-md|-lg|-xl}-show" />
							</AppSidebar>
							<main className="main">
								<AppBreadcrumb appRoutes={routes} />
								<Container fluid>
									<Switch>
										{
											routes.map(route => (
												route.component ? (
													<Route
														key={route.id}
														path={route.path}
														exact={route.exact}
														name={route.name}
														component={route.component}
													/>
												) : (null)
											))
										}
										<Redirect from="/" to="/Hardware" />
									</Switch>
								</Container>
							</main>
						</div>
					</EmployeeProvider>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({
	Employee,
	UI,
}) => ({
	employee: Employee.employee,
	...UI,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
