import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Branch,
		path: '/branch',
		exact: true,
		name: 'Sucursal',
		component: Loadable({
			loader: () => import('~/routes/Branch/BranchList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.BranchAdd,
		path: '/branch/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Branch/BranchAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.BranchEdit,
		path: '/branch/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Branch/BranchEdit'),
			loading: Loading,
		})
	}
];
