import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	modelGetAllRequest: false,
	modelGetAllOk: true,
	modelGetAllMessages: [],
	modelGetByIdRequest: false,
	modelGetByIdOk: true,
	modelGetByIdMessages: [],
	modelAddRequest: false,
	modelAddOk: true,
	modelAddMessages: [],
	modelEditRequest: false,
	modelEditOk: true,
	modelEditMessages: [],
	modelDeleteRequest: false,
	modelDeleteOk: true,
	modelDeleteMessages: [],
	models: [],
	model: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.MODEL_GETALL_START:
			return {
				...state,
				modelGetAllRequest: true,
				modelGetAllOk: true,
				modelGetAllMessages: [],
				models: initialState.models
			};
		case actionTypes.MODEL_GETALL_SUCCESS:
			return {
				...state,
				modelGetAllRequest: false,
				modelGetAllOk: true,
				modelGetAllMessages: [],
				models: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.MODEL_GETALL_ERROR:
			return {
				...state,
				modelGetAllRequest: false,
				modelGetAllOk: false,
				modelGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.MODEL_GETBYID_START:
			return {
				...state,
				modelGetByIdRequest: true,
				modelGetByIdOk: true,
				modelGetByIdMessages: [],
				model: null
			};
		case actionTypes.MODEL_GETBYID_SUCCESS:
			return {
				...state,
				modelGetByIdRequest: false,
				modelGetByIdOk: true,
				modelGetByIdMessages: [],
				model: action.payload
			};
		case actionTypes.MODEL_GETBYID_ERROR:
			return {
				...state,
				modelGetByIdRequest: false,
				modelGetByIdOk: false,
				modelGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.MODEL_ADD_START:
			return {
				...state,
				modelAddRequest: true,
				modelAddMessages: [],
				modelAddOk: true,
			};
		case actionTypes.MODEL_ADD_SUCCESS:
			return {
				...state,
				modelAddRequest: false,
				modelAddOk: true,
				modelAddMessages: [],
				models: [
					...state.models,
					action.payload
				]
			};
		case actionTypes.MODEL_ADD_ERROR:
			return {
				...state,
				modelAddRequest: false,
				modelAddOk: false,
				modelAddMessages: deepCopy(action.payload)
			};
		case actionTypes.MODEL_EDIT_START:
			return {
				...state,
				modelEditRequest: true,
				modelEditMessages: [],
				modelEditOk: true
			};
		case actionTypes.MODEL_EDIT_SUCCESS:
			return {
				...state,
				modelranchEditRequest: false,
				modelEditOk: true,
				modelEditMessages: [],
				models: [
					...state.models.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.MODEL_EDIT_ERROR:
			return {
				...state,
				modelEditRequest: false,
				modelEditOk: false,
				modelEditMessages: deepCopy(action.payload)
			};
		case actionTypes.MODEL_DELETE_START:
			return {
				...state,
				modelDeleteRequest: true,
				modelDeleteMessages: [],
				modelDeleteOk: true
			};
		case actionTypes.MODEL_DELETE_SUCCESS:
			return {
				...state,
				modelDeleteRequest: false,
				modelDeleteOk: true,
				modelDeleteMessages: [],
				models: [
					...state.models.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.MODEL_DELETE_ERROR:
			return {
				...state,
				modelDeleteRequest: false,
				modelDeleteOk: false,
				modelDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
