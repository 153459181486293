export default {
	COMMUNICATIONTYPE_GETALL_START: 'COMMUNICATIONTYPE_GETALL_START',
	COMMUNICATIONTYPE_GETALL_SUCCESS: 'COMMUNICATIONTYPE_GETALL_SUCCESS',
	COMMUNICATIONTYPE_GETALL_ERROR: 'COMMUNICATIONTYPE_GETALL_ERROR',
	COMMUNICATIONTYPE_GETBYID_START: 'COMMUNICATIONTYPE_GETBYID_START',
	COMMUNICATIONTYPE_GETBYID_SUCCESS: 'COMMUNICATIONTYPE_GETBYID_SUCCESS',
	COMMUNICATIONTYPE_GETBYID_ERROR: 'COMMUNICATIONTYPE_GETBYID_ERROR',
	COMMUNICATIONTYPE_ADD_START: 'COMMUNICATIONTYPE_ADD_START',
	COMMUNICATIONTYPE_ADD_SUCCESS: 'COMMUNICATIONTYPE_ADD_SUCCESS',
	COMMUNICATIONTYPE_ADD_ERROR: 'COMMUNICATIONTYPE_ADD_ERROR',
	COMMUNICATIONTYPE_EDIT_START: 'COMMUNICATIONTYPE_EDIT_START',
	COMMUNICATIONTYPE_EDIT_SUCCESS: 'COMMUNICATIONTYPE_EDIT_SUCCESS',
	COMMUNICATIONTYPE_EDIT_ERROR: 'COMMUNICATIONTYPE_EDIT_ERROR',
	COMMUNICATIONTYPE_DELETE_START: 'COMMUNICATIONTYPE_DELETE_START',
	COMMUNICATIONTYPE_DELETE_SUCCESS: 'COMMUNICATIONTYPE_DELETE_SUCCESS',
	COMMUNICATIONTYPE_DELETE_ERROR: 'COMMUNICATIONTYPE_DELETE_ERROR'
};
