export default {
	HARDWARE_GETALL_START: 'HARDWARE_GETALL_START',
	HARDWARE_GETALL_SUCCESS: 'HARDWARE_GETALL_SUCCESS',
	HARDWARE_GETALL_ERROR: 'HARDWARE_GETALL_ERROR',
	HARDWARE_GETBYID_START: 'HARDWARE_GETBYID_START',
	HARDWARE_GETBYID_SUCCESS: 'HARDWARE_GETBYID_SUCCESS',
	HARDWARE_GETBYID_ERROR: 'HARDWARE_GETBYID_ERROR',
	HARDWARE_ADD_START: 'HARDWARE_ADD_START',
	HARDWARE_ADD_SUCCESS: 'HARDWARE_ADD_SUCCESS',
	HARDWARE_ADD_ERROR: 'HARDWARE_ADD_ERROR',
	HARDWARE_EDIT_START: 'HARDWARE_EDIT_START',
	HARDWARE_EDIT_SUCCESS: 'HARDWARE_EDIT_SUCCESS',
	HARDWARE_EDIT_ERROR: 'HARDWARE_EDIT_ERROR',
	HARDWARE_DELETE_START: 'HARDWARE_DELETE_START',
	HARDWARE_DELETE_SUCCESS: 'HARDWARE_DELETE_SUCCESS',
	HARDWARE_DELETE_ERROR: 'HARDWARE_DELETE_ERROR',
	HARDWARE_GETBYTYPE_START: 'HARDWARE_GETBYTYPE_START',
	HARDWARE_GETBYTYPE_SUCCESS: 'HARDWARE_GETBYTYPE_SUCCESS',
	HARDWARE_GETBYTYPE_ERROR: 'HARDWARE_GETBYTYPE_ERROR',
	HARDWARE_SEARCH_START: 'HARDWARE_SEARCH_START',
	HARDWARE_SEARCH_SUCCESS: 'HARDWARE_SEARCH_SUCCESS',
	HARDWARE_SEARCH_ERROR: 'HARDWARE_SEARCH_ERROR',
	HARDWARE_SHOW_LOW: 'HARDWARE_SHOW_LOW',
	HARDWARE_GET_PINPADS_BY_BRANCH_START: 'HARDWARE_GET_PINPADS_BY_BRANCH_START',
	HARDWARE_GET_PINPADS_BY_BRANCH_SUCCESS: 'HARDWARE_GET_PINPADS_BY_BRANCH_SUCCESS',
	HARDWARE_GET_PINPADS_BY_BRANCH_ERROR: 'HARDWARE_GET_PINPADS_BY_BRANCH_ERROR',
	HARDWARE_GET_PRINTERS_BY_BRANCH_START: 'HARDWARE_GET_PRINTERS_BY_BRANCH_START',
	HARDWARE_GET_PRINTERS_BY_BRANCH_SUCCESS: 'HARDWARE_GET_PRINTERS_BY_BRANCH_SUCCESS',
	HARDWARE_GET_PRINTERS_BY_BRANCH_ERROR: 'HARDWARE_GET_PRINTERS_BY_BRANCH_ERROR',
	HARDWARE_CLEAR: 'HARDWARE_CLEAR',
};
