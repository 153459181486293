import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	societyGetAllRequest: false,
	societyGetAllOk: true,
	societyGetAllMessages: [],
	societyGetByIdRequest: false,
	societyGetByIdOk: true,
	societyGetByIdMessages: [],
	societyAddRequest: false,
	societyAddOk: true,
	societyAddMessages: [],
	societyEditRequest: false,
	societyEditOk: true,
	societyEditMessages: [],
	societyDeleteRequest: false,
	societyDeleteOk: true,
	societyDeleteMessages: [],
	societies: [],
	society: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.SOCIETY_GETALL_START:
			return {
				...state,
				societyGetAllRequest: true,
				societyGetAllOk: true,
				societyGetAllMessages: [],
				societies: initialState.societies
			};
		case actionTypes.SOCIETY_GETALL_SUCCESS:
			return {
				...state,
				societyGetAllRequest: false,
				societyGetAllOk: true,
				societyGetAllMessages: [],
				societies: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.SOCIETY_GETALL_ERROR:
			return {
				...state,
				societyGetAllRequest: false,
				societyGetAllOk: false,
				societyGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.SOCIETY_GETBYID_START:
			return {
				...state,
				societyGetByIdRequest: true,
				societyGetByIdOk: true,
				societyGetByIdMessages: [],
				society: null
			};
		case actionTypes.SOCIETY_GETBYID_SUCCESS:
			return {
				...state,
				societyGetByIdRequest: false,
				societyGetByIdOk: true,
				societyGetByIdMessages: [],
				society: action.payload
			};
		case actionTypes.SOCIETY_GETBYID_ERROR:
			return {
				...state,
				societyGetByIdRequest: false,
				societyGetByIdOk: false,
				societyGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.SOCIETY_ADD_START:
			return {
				...state,
				societyAddRequest: true,
				societyAddMessages: [],
				societyAddOk: true,
			};
		case actionTypes.SOCIETY_ADD_SUCCESS:
			return {
				...state,
				societyAddRequest: false,
				societyAddOk: true,
				societyAddMessages: [],
				societies: [
					...state.societies,
					action.payload
				]
			};
		case actionTypes.SOCIETY_ADD_ERROR:
			return {
				...state,
				societyAddRequest: false,
				societyAddOk: false,
				societyAddMessages: deepCopy(action.payload)
			};
		case actionTypes.SOCIETY_EDIT_START:
			return {
				...state,
				societyEditRequest: true,
				societyEditMessages: [],
				societyEditOk: true
			};
		case actionTypes.SOCIETY_EDIT_SUCCESS:
			return {
				...state,
				societyEditRequest: false,
				societyEditOk: true,
				societyEditMessages: [],
				societies: [
					...state.societies.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.SOCIETY_EDIT_ERROR:
			return {
				...state,
				societyEditRequest: false,
				societyEditOk: false,
				societyEditMessages: deepCopy(action.payload)
			};
		case actionTypes.SOCIETY_DELETE_START:
			return {
				...state,
				societyDeleteRequest: true,
				societyDeleteMessages: [],
				societyDeleteOk: true
			};
		case actionTypes.SOCIETY_DELETE_SUCCESS:
			return {
				...state,
				societyDeleteRequest: false,
				societyDeleteOk: true,
				societyDeleteMessages: [],
				societies: [
					...state.societies.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.SOCIETY_DELETE_ERROR:
			return {
				...state,
				societyDeleteRequest: false,
				societyDeleteOk: false,
				societyDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
