export default {
	BRANCH_GETALL_START: 'BRANCH_GETALL_START',
	BRANCH_GETALL_SUCCESS: 'BRANCH_GETALL_SUCCESS',
	BRANCH_GETALL_ERROR: 'BRANCH_GETALL_ERROR',
	BRANCH_GETBYID_START: 'BRANCH_GETBYID_START',
	BRANCH_GETBYID_SUCCESS: 'BRANCH_GETBYID_SUCCESS',
	BRANCH_GETBYID_ERROR: 'BRANCH_GETBYID_ERROR',
	BRANCH_ADD_START: 'BRANCH_ADD_START',
	BRANCH_ADD_SUCCESS: 'BRANCH_ADD_SUCCESS',
	BRANCH_ADD_ERROR: 'BRANCH_ADD_ERROR',
	BRANCH_EDIT_START: 'BRANCH_EDIT_START',
	BRANCH_EDIT_SUCCESS: 'BRANCH_EDIT_SUCCESS',
	BRANCH_EDIT_ERROR: 'BRANCH_EDIT_ERROR',
	BRANCH_DELETE_START: 'BRANCH_DELETE_START',
	BRANCH_DELETE_SUCCESS: 'BRANCH_DELETE_SUCCESS',
	BRANCH_DELETE_ERROR: 'BRANCH_DELETE_ERROR'
};
