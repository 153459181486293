export default {
	COMMUNICATIONUSES_GETALL_START: 'COMMUNICATIONUSES_GETALL_START',
	COMMUNICATIONUSES_GETALL_SUCCESS: 'COMMUNICATIONUSES_GETALL_SUCCESS',
	COMMUNICATIONUSES_GETALL_ERROR: 'COMMUNICATIONUSES_GETALL_ERROR',
	COMMUNICATIONUSES_GETBYID_START: 'COMMUNICATIONUSES_GETBYID_START',
	COMMUNICATIONUSES_GETBYID_SUCCESS: 'COMMUNICATIONUSES_GETBYID_SUCCESS',
	COMMUNICATIONUSES_GETBYID_ERROR: 'COMMUNICATIONUSES_GETBYID_ERROR',
	COMMUNICATIONUSES_ADD_START: 'COMMUNICATIONUSES_ADD_START',
	COMMUNICATIONUSES_ADD_SUCCESS: 'COMMUNICATIONUSES_ADD_SUCCESS',
	COMMUNICATIONUSES_ADD_ERROR: 'COMMUNICATIONUSES_ADD_ERROR',
	COMMUNICATIONUSES_EDIT_START: 'COMMUNICATIONUSES_EDIT_START',
	COMMUNICATIONUSES_EDIT_SUCCESS: 'COMMUNICATIONUSES_EDIT_SUCCESS',
	COMMUNICATIONUSES_EDIT_ERROR: 'COMMUNICATIONUSES_EDIT_ERROR',
	COMMUNICATIONUSES_DELETE_START: 'COMMUNICATIONUSES_DELETE_START',
	COMMUNICATIONUSES_DELETE_SUCCESS: 'COMMUNICATIONUSES_DELETE_SUCCESS',
	COMMUNICATIONUSES_DELETE_ERROR: 'COMMUNICATIONUSES_DELETE_ERROR'
};
