import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.HardwareType,
		path: '/hardwareType',
		exact: true,
		name: 'Tipo de dispositivo',
		component: Loadable({
			loader: () => import('~/routes/HardwareType/HardwareTypeList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.HardwareTypeAdd,
		path: '/hardwareType/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/HardwareType/HardwareTypeAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.HardwareTypeEdit,
		path: '/hardwareType/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/HardwareType/HardwareTypeEdit'),
			loading: Loading,
		})
	}
];
