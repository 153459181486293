import { combineReducers } from 'redux';

import Employee from './Employee';
import UI from './UI';
import Company from './Company';
import Branch from './Branch';
import Department from './Department';
import Brand from './Brand';
import Model from './Model';
import Supplier from './Supplier';
import HardwareType from './HardwareType';
import CommunicationTechnology from './CommunicationTechnology';
import CommunicationUses from './CommunicationUses';
import Service from './Service';
import MovementType from './MovementType';
import CommunicationType from './CommunicationType';
import Hardware from './Hardware';
import Communication from './Communication';
import Movement from './Movement';
import Society from './Society';

// COMBINE REDUCERS
export default combineReducers({
	Employee,
	UI,
	Company,
	Branch,
	Department,
	Brand,
	Model,
	Supplier,
	HardwareType,
	CommunicationTechnology,
	CommunicationUses,
	Service,
	MovementType,
	Hardware,
	Communication,
	CommunicationType,
	Movement,
	Society
});
