import actionTypes from '~/constants/ActionTypes';

const initialState = {
	sidebarisopen: "false",
	sidebarisminimize: "false",
	loaderisvisible: "false",
	requestcount: 0,
	message: '',
	icon: 'fa-spinner fa-pulse',
	feedbackopen: "false"
};

const uiReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.UI_TOGGLE_SIDEBAR:
			return {
				...state,
				sidebarisopen: !state.sidebarisopen
			};
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_START:
		case actionTypes.COMPANY_GETALL_START:
		case actionTypes.BRANCH_GETALL_START:
		case actionTypes.DEPARTMENT_GETALL_START:
		case actionTypes.BRAND_GETALL_START:
		case actionTypes.MODEL_GETALL_START:
		case actionTypes.SUPPLIER_GETALL_START:
		case actionTypes.HARDWARETYPE_GETALL_START:
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_START:
		case actionTypes.COMMUNICATIONUSES_GETALL_START:
		case actionTypes.SERVICE_GETALL_START:
		case actionTypes.MOVEMENTTYPE_GETALL_START:
		case actionTypes.SOCIETY_GETALL_START:
		case actionTypes.HARDWARE_GETALL_START:
		case actionTypes.HARDWARE_SEARCH_START:
		case actionTypes.COMMUNICATION_GETALL_START:
		case actionTypes.COMMUNICATION_SEARCH_START:
			return {
				...state,
				icon: action.payload && action.payload.icon ? action.payload.icon : initialState.icon,
				message: action.payload && action.payload.message ? action.payload.message : initialState.message,
				requestcount: state.requestcount + 1,
				loaderisvisible: "true",
			};
		case actionTypes.UI_TOGGLE_MINIMISE_SIDEBAR:
			return {
				...state,
				sidebarisminimize: !state.sidebarisminimize
			};
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_SUCCESS:
		case actionTypes.EMPLOYEE_LOGIN_REQUEST_ERROR:
		case actionTypes.COMPANY_GETALL_SUCCESS:
		case actionTypes.COMPANY_GETALL_ERROR:
		case actionTypes.BRANCH_GETALL_SUCCESS:
		case actionTypes.BRANCH_GETALL_ERROR:
		case actionTypes.DEPARTMENT_GETALL_SUCCESS:
		case actionTypes.DEPARTMENT_GETALL_ERROR:
		case actionTypes.BRAND_GETALL_SUCCESS:
		case actionTypes.BRAND_GETALL_ERROR:
		case actionTypes.MODEL_GETALL_SUCCESS:
		case actionTypes.MODEL_GETALL_ERROR:
		case actionTypes.SUPPLIER_GETALL_SUCCESS:
		case actionTypes.SUPPLIER_GETALL_ERROR:
		case actionTypes.HARDWARETYPE_GETALL_SUCCESS:
		case actionTypes.HARDWARETYPE_GETALL_ERROR:
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_SUCCESS:
		case actionTypes.COMMUNICATIONTECHNOLOGY_GETALL_ERROR:
		case actionTypes.COMMUNICATIONUSES_GETALL_SUCCESS:
		case actionTypes.COMMUNICATIONUSES_GETALL_ERROR:
		case actionTypes.SERVICE_GETALL_SUCCESS:
		case actionTypes.SERVICE_GETALL_ERROR:
		case actionTypes.SOCIETY_GETALL_SUCCESS:
		case actionTypes.SOCIETY_GETALL_ERROR:
		case actionTypes.MOVEMENTTYPE_GETALL_SUCCESS:
		case actionTypes.MOVEMENTTYPE_GETALL_ERROR:
		case actionTypes.HARDWARE_GETALL_SUCCESS:
		case actionTypes.HARDWARE_GETALL_ERROR:
		case actionTypes.HARDWARE_SEARCH_SUCCESS:
		case actionTypes.HARDWARE_SEARCH_ERROR:
		case actionTypes.COMMUNICATION_GETALL_SUCCESS:
		case actionTypes.COMMUNICATION_GETALL_ERROR:
		case actionTypes.COMMUNICATION_SEARCH_SUCCESS:
		case actionTypes.COMMUNICATION_SEARCH_ERROR:
			return {
				...state,
				loaderisvisible: (state.requestcount - 1) > 0 ? "true" : "false",
				requestcount: state.requestcount - 1,
				message: initialState.message,
				icon: initialState.icon
			};
		default:
			return state;
	}
};
export default uiReducer;
