export default {
	SOCIETY_GETALL_START: 'SOCIETY_GETALL_START',
	SOCIETY_GETALL_SUCCESS: 'SOCIETY_GETALL_SUCCESS',
	SOCIETY_GETALL_ERROR: 'SOCIETY_GETALL_ERROR',
	SOCIETY_GETBYID_START: 'SOCIETY_GETBYID_START',
	SOCIETY_GETBYID_SUCCESS: 'SOCIETY_GETBYID_SUCCESS',
	SOCIETY_GETBYID_ERROR: 'SOCIETY_GETBYID_ERROR',
	SOCIETY_ADD_START: 'SOCIETY_ADD_START',
	SOCIETY_ADD_SUCCESS: 'SOCIETY_ADD_SUCCESS',
	SOCIETY_ADD_ERROR: 'SOCIETY_ADD_ERROR',
	SOCIETY_EDIT_START: 'SOCIETY_EDIT_START',
	SOCIETY_EDIT_SUCCESS: 'SOCIETY_EDIT_SUCCESS',
	SOCIETY_EDIT_ERROR: 'SOCIETY_EDIT_ERROR',
	SOCIETY_DELETE_START: 'SOCIETY_DELETE_START',
	SOCIETY_DELETE_SUCCESS: 'SOCIETY_DELETE_SUCCESS',
	SOCIETY_DELETE_ERROR: 'SOCIETY_DELETE_ERROR'
};
