import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Hardware,
		path: '/hardware',
		exact: true,
		name: 'Dispositivo',
		component: Loadable({
			loader: () => import('~/routes/Hardware/HardwareList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.HardwareAdd,
		path: '/hardware/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Hardware/HardwareAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.HardwareEdit,
		path: '/hardware/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Hardware/HardwareEdit'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.HardwareCopy,
		path: '/hardware/Copy/:id',
		exact: true,
		name: 'Copiar',
		component: Loadable({
			loader: () => import('~/routes/Hardware/HardwareCopy'),
			loading: Loading,
		})
	}
];
