export default {
	MOVEMENT_GETALL_START: 'MOVEMENT_GETALL_START',
	MOVEMENT_GETALL_SUCCESS: 'MOVEMENT_GETALL_SUCCESS',
	MOVEMENT_GETALL_ERROR: 'MOVEMENT_GETALL_ERROR',
	MOVEMENT_GETBYID_START: 'MOVEMENT_GETBYID_START',
	MOVEMENT_GETBYID_SUCCESS: 'MOVEMENT_GETBYID_SUCCESS',
	MOVEMENT_GETBYID_ERROR: 'MOVEMENT_GETBYID_ERROR',
	MOVEMENT_ADD_START: 'MOVEMENT_ADD_START',
	MOVEMENT_ADD_SUCCESS: 'MOVEMENT_ADD_SUCCESS',
	MOVEMENT_ADD_ERROR: 'MOVEMENT_ADD_ERROR',
	MOVEMENT_EDIT_START: 'MOVEMENT_EDIT_START',
	MOVEMENT_EDIT_SUCCESS: 'MOVEMENT_EDIT_SUCCESS',
	MOVEMENT_EDIT_ERROR: 'MOVEMENT_EDIT_ERROR',
	MOVEMENT_DELETE_START: 'MOVEMENT_DELETE_START',
	MOVEMENT_DELETE_SUCCESS: 'MOVEMENT_DELETE_SUCCESS',
	MOVEMENT_DELETE_ERROR: 'MOVEMENT_DELETE_ERROR',
	MOVEMENT_GETBYHARDWARE_START: 'MOVEMENT_GETBYHARDWARE_START',
	MOVEMENT_GETBYHARDWARE_SUCCESS: 'MOVEMENT_GETBYHARDWARE_SUCCESS',
	MOVEMENT_GETBYHARDWARE_ERROR: 'MOVEMENT_GETBYHARDWARE_ERROR',
	MOVEMENT_GETBYCOMMUNICATION_START: 'MOVEMENT_GETBYCOMMUNICATION_START',
	MOVEMENT_GETBYCOMMUNICATION_SUCCESS: 'MOVEMENT_GETBYCOMMUNICATION_SUCCESS',
	MOVEMENT_GETBYCOMMUNICATION_ERROR: 'MOVEMENT_GETBYCOMMUNICATION_ERROR'
};
