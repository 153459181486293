import EmployeeEndPoints from '~/constants/EndPoints/EmployeeEndPoints';
import { api } from '~/helpers';

/**
 * Log in a employee with its username and password.
 * @param {string} username Username of the employee.
 * @param {string} password Password of the employee.
 * @return {Promise}
 */
export const logIn = async (username, password) => (
	api.get(EmployeeEndPoints.LOGIN, { username, password })
);

/**
 * Log out the current employee.
 * @returns {Promise} `void`
 */
export const logOut = async () => (
	api.get(EmployeeEndPoints.LOGOUT)
);
