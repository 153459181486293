
export default {
	/**
	 * @host '/employee/login/'
	 */
	LOGIN: '/employee/login/',

	/**
	 * @host '/employee/logout/'
	 */
	LOGOUT: '/employee/logout/',
};
