import actionTypes from '~/constants/ActionTypes';
import * as EmployeeService from '~/services/EmployeeService';

const logInRequestStart = () => ({
	type: actionTypes.EMPLOYEE_LOGIN_REQUEST_START
});

const logInRequestSuccess = employee => ({
	type: actionTypes.EMPLOYEE_LOGIN_REQUEST_SUCCESS,
	employee
});

const logInRequestError = messages => ({
	type: actionTypes.EMPLOYEE_LOGIN_REQUEST_ERROR,
	messages
});

/**
 * Log in an employee with his username and password.
 * @param {string} username Username of the employee.
 * @param {string} password Password of the employee
 */
const logIn = (username, password) => async (dispatch) => {
	dispatch(logInRequestStart());
	const response = await EmployeeService.logIn(username, password);
	if (response.ok) {
		dispatch(logInRequestSuccess(response.data));
	} else {
		dispatch(logInRequestError(response.errors));
	}
};

/**
 * Log out an employee.
 */
const logOut = () => async (dispatch) => {
	dispatch({ type: actionTypes.CLEAR_CACHE });
};


export default {
	logIn,
	logOut
};
