export default {
	COMPANY_GETALL_START: 'COMPANY_GETALL_START',
	COMPANY_GETALL_SUCCESS: 'COMPANY_GETALL_SUCCESS',
	COMPANY_GETALL_ERROR: 'COMPANY_GETALL_ERROR',
	COMPANY_GETBYID_START: 'COMPANY_GETBYID_START',
	COMPANY_GETBYID_SUCCESS: 'COMPANY_GETBYID_SUCCESS',
	COMPANY_GETBYID_ERROR: 'COMPANY_GETBYID_ERROR',
	COMPANY_ADD_START: 'COMPANY_ADD_START',
	COMPANY_ADD_SUCCESS: 'COMPANY_ADD_SUCCESS',
	COMPANY_ADD_ERROR: 'COMPANY_ADD_ERROR',
	COMPANY_EDIT_START: 'COMPANY_EDIT_START',
	COMPANY_EDIT_SUCCESS: 'COMPANY_EDIT_SUCCESS',
	COMPANY_EDIT_ERROR: 'COMPANY_EDIT_ERROR',
	COMPANY_DELETE_START: 'COMPANY_DELETE_START',
	COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
	COMPANY_DELETE_ERROR: 'COMPANY_DELETE_ERROR',
};
