export default {
	MOVEMENTTYPE_GETALL_START: 'MOVEMENTTYPE_GETALL_START',
	MOVEMENTTYPE_GETALL_SUCCESS: 'MOVEMENTTYPE_GETALL_SUCCESS',
	MOVEMENTTYPE_GETALL_ERROR: 'MOVEMENTTYPE_GETALL_ERROR',
	MOVEMENTTYPE_GETBYID_START: 'MOVEMENTTYPE_GETBYID_START',
	MOVEMENTTYPE_GETBYID_SUCCESS: 'MOVEMENTTYPE_GETBYID_SUCCESS',
	MOVEMENTTYPE_GETBYID_ERROR: 'MOVEMENTTYPE_GETBYID_ERROR',
	MOVEMENTTYPE_ADD_START: 'MOVEMENTTYPE_ADD_START',
	MOVEMENTTYPE_ADD_SUCCESS: 'MOVEMENTTYPE_ADD_SUCCESS',
	MOVEMENTTYPE_ADD_ERROR: 'MOVEMENTTYPE_ADD_ERROR',
	MOVEMENTTYPE_EDIT_START: 'MOVEMENTTYPE_EDIT_START',
	MOVEMENTTYPE_EDIT_SUCCESS: 'MOVEMENTTYPE_EDIT_SUCCESS',
	MOVEMENTTYPE_EDIT_ERROR: 'MOVEMENTTYPE_EDIT_ERROR',
	MOVEMENTTYPE_DELETE_START: 'MOVEMENTTYPE_DELETE_START',
	MOVEMENTTYPE_DELETE_SUCCESS: 'MOVEMENTTYPE_DELETE_SUCCESS',
	MOVEMENTTYPE_DELETE_ERROR: 'MOVEMENTTYPE_DELETE_ERROR'
};
