import Swal from 'sweetalert2';

/**
 * 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end'.
 */
const POSITIONS = {
	top: 'top',
	topStart: 'top-start',
	topEnd: 'top-end',
	center: 'center',
	centerStart: 'center-start',
	centerEnd: 'center-end',
	bottom: 'bottom',
	bottomStart: 'bottom-start',
	bottomEnd: 'bottom-end'
};

/**
 * warning, error, success, info, and question
 */
const TYPES = {
	warning: 'warning',
	error: 'error',
	success: 'success',
	info: 'info',
	question: 'primary'
};

/**
 * https://sweetalert2.github.io/#configuration
 */
const defaultToastConfig = {
	toast: true,
	position: POSITIONS.bottomEnd,
	type: TYPES.info,
	showConfirmButton: false,
	timer: 5000
};

const defaultConfig = {
	allowOutsideClick: true,
	allowEscapeKey: true,
	showConfirmButton: true,
	showCancelButton: true,
	confirmButtonText: 'ok',
	cancelButtonText: 'Cancel',

	buttonsStyling: false,
	cancelButtonClass: 'btn btn-lg btn-danger',
	confirmButtonClass: 'btn btn-lg btn-primary',
	reverseButtons: true,
	focusCancel: true
};

export const GCSwal = (
	title,
	text,
	type = 'error',
	html = null,
	isConfirm = false,
	confirmButtonText = defaultConfig.confirmButtonText,
	cancelButtonText = defaultConfig.cancelButtonText
) => Swal.fire({
	...defaultConfig,
	title,
	text,
	type,
	showCancelButton: isConfirm,
	confirmButtonText,
	cancelButtonText,
	html
});

export const GCToast = (
	title,
	text,
	type = 'error',
) => Swal.fire({
	...defaultToastConfig,
	text,
	title,
	type
});
