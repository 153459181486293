import api from './api';
import history from './history';
import { DateTime } from 'luxon';

import * as formHelper from '~/helpers/forms';

/**
 * Change first latter to uppercase
 * @param {string} string value to capitalize
 * @returns {string} `string` string capitalize
 */
export const capitalize = (string = '') => {
	if (string && string.length > 0) {
		return `${string.charAt(0).toUpperCase()}${string.slice(1).toLocaleLowerCase()}`;
	}
	return string;
};

/**
 * remplace string by newString
 * @param {string} string string remplace
 * @param {string} newstring new string remplace
 */
export const replaceUnderScore = (string = '', newstring = ' ') => string.replace(new RegExp('_', 'g'), newstring);

/**
 * Find key by object value
 * @param {Object} object Object type
 * @param {any} value value in object
 */
export const getKeyByValue = (object, value) => (
	Object.keys(object).find(key => object[key] === value)
);

export const separeCamelCase = (text) => {
	let result = text.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2');
	result = result.toLowerCase().trim();
	result = capitalize(result);
	return result;
};

/**
 *
 * @param {Object} object Object type
 * @param {any} value Object value
 */
export const normalizeStateCode = (object, value) => (
	capitalize(replaceUnderScore(getKeyByValue(object, value)))
);

/**
 * Remove comment from list comment
 * @param {array} list List comments
 * @param {object} object Object comment
 * @param {string} key Name field for search in list
 */
export const removeItemFromList = (list, object, key) => {
	if (object) {
		const index = list.findIndex(c => c[key] === object[key]);
		if (index > -1) {
			list.splice(index, 1);
		}
	}

	return (list && list.length > 0) ? list : [];
};

export const deepCopy = o => JSON.parse(JSON.stringify(o));

export const TruncateText = (text, maxLength) => {
	if (text && maxLength && text.length > maxLength) {
		return `${text.substring(0, maxLength)}...`;
	}

	return text;
};

export const getFormatNumber = (value, type) => {
	let finalvalue = value;
	let config = {};

	switch (type) {
		case 'percent':
			finalvalue = Number(finalvalue / 100);

			config = {
				...config,
				style: 'percent'
			};
			break;
		case 'currency':
			config = {
				...config,
				currency: 'EUR',
				style: 'currency'
			};
			break;

		default:
			config = {
				...config,
				style: 'decimal'
			};
			break;
	}

	return new Intl.NumberFormat('es-ES', { ...config }).format(finalvalue);
};

export const GetListHours = (timeZone = null) => {
	const quarterHours = ['00', '15', '30', '45'];
	const times = [];
	let currentDate = DateTime.utc().toJSDate();

	if (timeZone) {
		currentDate = DateTime.utc().setZone(timeZone).toJSDate();
	}

	for (let i = 0; i < 24; i += 1) {
		for (let j = 0; j < 4; j += 1) {
			times.push({
				label: `${i.toString().padStart(2, '0')}:${quarterHours[j]}`,
				// value: new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), i, quarterHours[j]),
				value: `${i.toString().padStart(2, '0')}:${quarterHours[j]}:00`,
				default: i === currentDate.getHours() + 1
			});
		}
	}

	return times;
};

export const calculateAge = (date) => {
	const now = DateTime.utc();
	const { years } = now.diff(date, ['year']);

	// eslint-disable-next-line radix
	return parseInt(years);
};

export const calculateAgeToPickUp = (pickup, birth) => {
	if (!pickup || !birth) {
		return null;
	}

	let pickUpDate = pickup;
	let birthDate = birth;

	if (typeof pickUpDate === 'string') {
		pickUpDate = DateTime.fromISO(pickUpDate);
	}

	if (typeof birthDate === 'string') {
		birthDate = DateTime.fromISO(birthDate);
	} else {
		birthDate = DateTime.fromJSDate(birthDate);
	}

	const { years } = pickUpDate.diff(birthDate, ['year']);

	// eslint-disable-next-line radix
	return parseInt(years);
};

export const round = (value, decimals = 2) => (
	Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)
);

const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg', 'webp'];

export const isImageExtension = extension => imageExtensions.includes(extension.replace('.', ''));

export const getLocalIP = () => new Promise((resolve, reject) => {
	try {
		window.RTCPeerConnection = window.RTCPeerConnection
			|| window.mozRTCPeerConnection
			|| window.webkitRTCPeerConnection;

		if (typeof window.RTCPeerConnection === 'undefined') {
			resolve(null);
		}

		const pc = new RTCPeerConnection();
		let finish = false;

		pc.createDataChannel('');

		pc.createOffer()
			.then(offer => pc.setLocalDescription(offer))
			.catch(err => reject(err));

		pc.onicecandidate = (event) => {
			if (!event || !event.candidate) {
				if (!finish) {
					resolve(null);
				}
				return;
			}
			if (finish) {
				return;
			}
			finish = true;
			resolve(event.candidate.candidate.split(' ')[4]);
		};
	} catch {
		resolve(null);
	}
});

/**
 * Group array by one property
 * @param {Array} items
 * @param {Function} keyGetter Function Callback return property for grouping
 * @example
 * const vehiclesByPlateNumber = GroupBy(vehicles, item => item.plateNumber);
 */
export const GroupBy = (items, keyGetter) => {
	const map = new Map();
	items.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
};

export const GetGcAgenciesByProvider = provider => Object.keys(provider).filter(x => x.toLowerCase().includes('agencycode')).map(x => provider[x]);

const branches = (localStorage.getItem('branches') && JSON.parse(localStorage.getItem('branches'))) || [];
export const getTimeZoneByBranch = (branch) => {
	if (branch) {
		const branchTimeZone = branches.find(x => x.code === branch);
		if (branchTimeZone && branchTimeZone.timeZone) {
			// eslint-disable-next-line
			return branchTimeZone.timeZone;
		}
	}
	return 'UTC';
};

export const getProviderByBranch = branch => (
	branches.find(x => x.code === branch).provider
);

const providers = (localStorage.getItem('providers') && JSON.parse(localStorage.getItem('providers'))) || [];
export const getProviderObjectByBranch = (branch) => {
	const providerName = branches.find(x => x.code === branch).provider;
	return providers.find(x => x.name === providerName);
};

export const getProviderObjectByBranchAllowed = (branch, allowedBranches) => {
	if (allowedBranches) {
		const providerName = allowedBranches.find(x => x.code === branch).provider;
		return providers.find(x => x.name === providerName);
	}

	return null;
};

export const GetGcBranchByProvider = provider => Object.keys(provider).filter(x => x.includes('AgencyCode')).map(prop => provider[prop]);

export const SetDocumentTitle = (title) => {
	const DefaultTitle = 'Centauro Rent A Car';
	document.title = `${title} | ${DefaultTitle}`;
};

export const IsDevEnvironment = () => {
	// eslint-disable-next-line no-restricted-globals
	const HOSTNAME = location.hostname.toLocaleLowerCase();
	return HOSTNAME.includes('grupocentauro.com') || HOSTNAME.includes('localhost');
};

export const getOnlyDateFromJsDate = (date) => {
    let currentDate = DateTime.local();
    if (typeof date === 'string') {
        currentDate = DateTime.fromISO(date);
    } else {
        currentDate = DateTime.fromJSDate(date);
    }
    if (currentDate.isValid) {
        return `${currentDate.year}-${zeroPrefix(currentDate.month)}-${currentDate.day}T00:00:00.000Z`;
    }
    return '';
};

export const zeroPrefix = (num) => (num < 10 ? `0${num}` : `${num}`);

export {
	api,
	formHelper,
	history
};
