import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	brandGetAllRequest: false,
	brandGetAllOk: true,
	brandGetAllMessages: [],
	brandGetByIdRequest: false,
	brandGetByIdOk: true,
	brandGetByIdMessages: [],
	brandAddRequest: false,
	brandAddOk: true,
	brandAddMessages: [],
	brandEditRequest: false,
	brandEditOk: true,
	brandEditMessages: [],
	brandDeleteRequest: false,
	brandDeleteOk: true,
	brandDeleteMessages: [],
	brands: [],
	brand: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.BRAND_GETALL_START:
			return {
				...state,
				brandGetAllRequest: true,
				brandGetAllOk: true,
				brandGetAllMessages: [],
				brands: initialState.brands
			};
		case actionTypes.BRAND_GETALL_SUCCESS:
			return {
				...state,
				brandGetAllRequest: false,
				brandGetAllOk: true,
				brandGetAllMessages: [],
				brands: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.BRAND_GETALL_ERROR:
			return {
				...state,
				brandGetAllRequest: false,
				brandGetAllOk: false,
				brandGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.BRAND_GETBYID_START:
			return {
				...state,
				brandGetByIdRequest: true,
				brandGetByIdOk: true,
				brandGetByIdMessages: [],
				brand: null
			};
		case actionTypes.BRAND_GETBYID_SUCCESS:
			return {
				...state,
				brandGetByIdRequest: false,
				brandGetByIdOk: true,
				brandGetByIdMessages: [],
				brand: action.payload
			};
		case actionTypes.BRAND_GETBYID_ERROR:
			return {
				...state,
				brandGetByIdRequest: false,
				brandGetByIdOk: false,
				brandGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.BRAND_ADD_START:
			return {
				...state,
				brandAddRequest: true,
				brandAddMessages: [],
				brandAddOk: true,
			};
		case actionTypes.BRAND_ADD_SUCCESS:
			return {
				...state,
				brandAddRequest: false,
				brandAddOk: true,
				brandAddMessages: [],
				brands: [
					...state.brands,
					action.payload
				]
			};
		case actionTypes.BRAND_ADD_ERROR:
			return {
				...state,
				brandAddRequest: false,
				brandAddOk: false,
				brandAddMessages: deepCopy(action.payload)
			};
		case actionTypes.BRAND_EDIT_START:
			return {
				...state,
				brandEditRequest: true,
				brandEditMessages: [],
				brandEditOk: true
			};
		case actionTypes.BRAND_EDIT_SUCCESS:
			return {
				...state,
				brandranchEditRequest: false,
				brandEditOk: true,
				brandEditMessages: [],
				brands: [
					...state.brands.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.BRAND_EDIT_ERROR:
			return {
				...state,
				brandEditRequest: false,
				brandEditOk: false,
				brandEditMessages: deepCopy(action.payload)
			};
		case actionTypes.BRAND_DELETE_START:
			return {
				...state,
				brandDeleteRequest: true,
				brandDeleteMessages: [],
				brandDeleteOk: true
			};
		case actionTypes.BRAND_DELETE_SUCCESS:
			return {
				...state,
				brandDeleteRequest: false,
				brandDeleteOk: true,
				brandDeleteMessages: [],
				brands: [
					...state.brands.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.BRAND_DELETE_ERROR:
			return {
				...state,
				brandDeleteRequest: false,
				brandDeleteOk: false,
				brandDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
