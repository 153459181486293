import React from 'react';
import {
	DropdownMenu,
	DropdownToggle,
	Nav
} from 'reactstrap';
import {
	AppHeaderDropdown,
	AppNavbarBrand,
	AppSidebarToggler,
	AppAsideToggler
} from '@coreui/react';
import { Link } from 'react-router-dom';

import logo from '~/assets/img/brand/logo.svg';
import sygnet from '~/assets/img/brand/gc-logo-symbol.png';
import EmployeePropType from '~/constants/PropTypes/EmployeePropType';

const DefaultHeader = ({ employee }) => (
	<React.Fragment>
		<AppSidebarToggler className="d-lg-none" display="md" mobile />
		<AppNavbarBrand
			full={{
				src: logo,
				width: 160,
				height: 50,
				alt: 'Centauro Rent a Car',
			}}
			minimized={{
				src: sygnet,
				width: 30,
				height: 30,
				alt: 'Centauro Rent a Car',
			}}
		/>
		<AppSidebarToggler className="d-md-down-none" display="lg" />
		<Nav className="ml-auto" navbar>
			<AppHeaderDropdown direction="down">
				<DropdownToggle nav>
					<strong className="mr-4">
						<i className="fa fa-fw fa-user-o" />
						{' '}
						<span className="d-none d-sm-inline-block">{employee.userName}&nbsp;&nbsp;</span>
					</strong>
				</DropdownToggle>
				<DropdownMenu right style={{ right: 'auto' }}>
					<Link to="/login" className="dropdown-item"><i className="fa fa-fw fa-sign-out" /> Cerrar sesión</Link>
				</DropdownMenu>
			</AppHeaderDropdown>
		</Nav>
	</React.Fragment>
);

DefaultHeader.propTypes = {
	employee: EmployeePropType.isRequired
};

export default React.memo(DefaultHeader);
