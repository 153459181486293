import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	supplierGetAllRequest: false,
	supplierGetAllOk: true,
	supplierGetAllMessages: [],
	supplierGetByIdRequest: false,
	supplierGetByIdOk: true,
	supplierGetByIdMessages: [],
	supplierAddRequest: false,
	supplierAddOk: true,
	supplierAddMessages: [],
	supplierEditRequest: false,
	supplierEditOk: true,
	supplierEditMessages: [],
	supplierDeleteRequest: false,
	supplierDeleteOk: true,
	supplierDeleteMessages: [],
	suppliers: [],
	supplier: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.SUPPLIER_GETALL_START:
			return {
				...state,
				supplierGetAllRequest: true,
				supplierGetAllOk: true,
				supplierGetAllMessages: [],
				suppliers: initialState.suppliers
			};
		case actionTypes.SUPPLIER_GETALL_SUCCESS:
			return {
				...state,
				supplierGetAllRequest: false,
				supplierGetAllOk: true,
				supplierGetAllMessages: [],
				suppliers: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.SUPPLIER_GETALL_ERROR:
			return {
				...state,
				supplierGetAllRequest: false,
				supplierGetAllOk: false,
				supplierGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.SUPPLIER_GETBYID_START:
			return {
				...state,
				supplierGetByIdRequest: true,
				supplierGetByIdOk: true,
				supplierGetByIdMessages: [],
				supplier: null
			};
		case actionTypes.SUPPLIER_GETBYID_SUCCESS:
			return {
				...state,
				supplierGetByIdRequest: false,
				supplierGetByIdOk: true,
				supplierGetByIdMessages: [],
				supplier: action.payload
			};
		case actionTypes.SUPPLIER_GETBYID_ERROR:
			return {
				...state,
				supplierGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.SUPPLIER_ADD_START:
			return {
				...state,
				supplierAddRequest: true,
				supplierAddMessages: [],
				supplierAddOk: true,
			};
		case actionTypes.SUPPLIER_ADD_SUCCESS:
			return {
				...state,
				supplierAddRequest: false,
				supplierAddOk: true,
				supplierAddMessages: [],
				suppliers: [
					...state.suppliers,
					action.payload
				]
			};
		case actionTypes.SUPPLIER_ADD_ERROR:
			return {
				...state,
				supplierAddRequest: false,
				supplierAddOk: false,
				supplierAddMessages: deepCopy(action.payload)
			};
		case actionTypes.SUPPLIER_EDIT_START:
			return {
				...state,
				supplierEditRequest: true,
				supplierEditMessages: [],
				supplierEditOk: true
			};
		case actionTypes.SUPPLIER_EDIT_SUCCESS:
			return {
				...state,
				supplierEditRequest: false,
				supplierEditOk: true,
				supplierEditMessages: [],
				suppliers: [
					...state.suppliers.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.SUPPLIER_EDIT_ERROR:
			return {
				...state,
				supplierEditRequest: false,
				supplierEditOk: false,
				supplierEditMessages: deepCopy(action.payload)
			};
		case actionTypes.SUPPLIER_DELETE_START:
			return {
				...state,
				supplierDeleteRequest: true,
				supplierDeleteMessages: [],
				supplierDeleteOk: true
			};
		case actionTypes.SUPPLIER_DELETE_SUCCESS:
			return {
				...state,
				supplierDeleteRequest: false,
				supplierDeleteOk: true,
				supplierDeleteMessages: [],
				suppliers: [
					...state.suppliers.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.SUPPLIER_DELETE_ERROR:
			return {
				...state,
				supplierDeleteRequest: false,
				supplierDeleteOk: false,
				supplierDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
