export default {
	SERVICE_GETALL_START: 'SERVICE_GETALL_START',
	SERVICE_GETALL_SUCCESS: 'SERVICE_GETALL_SUCCESS',
	SERVICE_GETALL_ERROR: 'SERVICE_GETALL_ERROR',
	SERVICE_GETBYID_START: 'SERVICE_GETBYID_START',
	SERVICE_GETBYID_SUCCESS: 'SERVICE_GETBYID_SUCCESS',
	SERVICE_GETBYID_ERROR: 'SERVICE_GETBYID_ERROR',
	SERVICE_ADD_START: 'SERVICE_ADD_START',
	SERVICE_ADD_SUCCESS: 'SERVICE_ADD_SUCCESS',
	SERVICE_ADD_ERROR: 'SERVICE_ADD_ERROR',
	SERVICE_EDIT_START: 'SERVICE_EDIT_START',
	SERVICE_EDIT_SUCCESS: 'SERVICE_EDIT_SUCCESS',
	SERVICE_EDIT_ERROR: 'SERVICE_EDIT_ERROR',
	SERVICE_DELETE_START: 'SERVICE_DELETE_START',
	SERVICE_DELETE_SUCCESS: 'SERVICE_DELETE_SUCCESS',
	SERVICE_DELETE_ERROR: 'SERVICE_DELETE_ERROR'
};
