import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Company,
		path: '/company',
		exact: true,
		name: 'Empresa',
		component: Loadable({
			loader: () => import('~/routes/Company/CompanyList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CompanyAdd,
		path: '/company/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Company/CompanyAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CompanyEdit,
		path: '/company/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Company/CompanyEdit'),
			loading: Loading,
		})
	}
];
