import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.CommunicationUses,
		path: '/communicationUses',
		exact: true,
		name: 'Uso de comunicación',
		component: Loadable({
			loader: () => import('~/routes/CommunicationUses/CommunicationUsesList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationUsesAdd,
		path: '/communicationUses/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/CommunicationUses/CommunicationUsesAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationUsesEdit,
		path: '/communicationUses/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/CommunicationUses/CommunicationUsesEdit'),
			loading: Loading,
		})
	}
];
