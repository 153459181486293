
import hardwareRoutes from './hardwareRoutes';
import communicationRoutes from './communicationRoutes';
import companyRoutes from './companyRoutes';
import branchRoutes from './branchRoutes';
import departmentRoutes from './departmentRoutes';
import brandRoutes from './brandRoutes';
import modelRoutes from './modelRoutes';
import supplierRoutes from './supplierRoutes';
import hardwareTypeRoutes from './hardwareTypeRoutes';
import communicationTechnologyRoutes from './communicationTechnologyRoutes';
import communicationUsesRoutes from './communicationUsesRoutes';
import communicationTypeRoutes from './communicationTypeRoutes';
import serviceRoutes from './serviceRoutes';
import movementRoutes from './movementRoutes';

export default [
	...hardwareRoutes,
	...communicationRoutes,
	...companyRoutes,
	...branchRoutes,
	...departmentRoutes,
	...brandRoutes,
	...modelRoutes,
	...supplierRoutes,
	...hardwareTypeRoutes,
	...communicationTechnologyRoutes,
	...communicationUsesRoutes,
	...serviceRoutes,
	...movementRoutes,
	...communicationTypeRoutes
];
