export default {
	COMMUNICATIONTECHNOLOGY_GETALL_START: 'COMMUNICATIONTECHNOLOGY_GETALL_START',
	COMMUNICATIONTECHNOLOGY_GETALL_SUCCESS: 'COMMUNICATIONTECHNOLOGY_GETALL_SUCCESS',
	COMMUNICATIONTECHNOLOGY_GETALL_ERROR: 'COMMUNICATIONTECHNOLOGY_GETALL_ERROR',
	COMMUNICATIONTECHNOLOGY_GETBYID_START: 'COMMUNICATIONTECHNOLOGY_GETBYID_START',
	COMMUNICATIONTECHNOLOGY_GETBYID_SUCCESS: 'COMMUNICATIONTECHNOLOGY_GETBYID_SUCCESS',
	COMMUNICATIONTECHNOLOGY_GETBYID_ERROR: 'COMMUNICATIONTECHNOLOGY_GETBYID_ERROR',
	COMMUNICATIONTECHNOLOGY_ADD_START: 'COMMUNICATIONTECHNOLOGY_ADD_START',
	COMMUNICATIONTECHNOLOGY_ADD_SUCCESS: 'COMMUNICATIONTECHNOLOGY_ADD_SUCCESS',
	COMMUNICATIONTECHNOLOGY_ADD_ERROR: 'COMMUNICATIONTECHNOLOGY_ADD_ERROR',
	COMMUNICATIONTECHNOLOGY_EDIT_START: 'COMMUNICATIONTECHNOLOGY_EDIT_START',
	COMMUNICATIONTECHNOLOGY_EDIT_SUCCESS: 'COMMUNICATIONTECHNOLOGY_EDIT_SUCCESS',
	COMMUNICATIONTECHNOLOGY_EDIT_ERROR: 'COMMUNICATIONTECHNOLOGY_EDIT_ERROR',
	COMMUNICATIONTECHNOLOGY_DELETE_START: 'COMMUNICATIONTECHNOLOGY_DELETE_START',
	COMMUNICATIONTECHNOLOGY_DELETE_SUCCESS: 'COMMUNICATIONTECHNOLOGY_DELETE_SUCCESS',
	COMMUNICATIONTECHNOLOGY_DELETE_ERROR: 'COMMUNICATIONTECHNOLOGY_DELETE_ERROR'
};
