import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	branchGetAllRequest: false,
	branchGetAllOk: true,
	branchGetAllMessages: [],
	branchGetByIdRequest: false,
	branchGetByIdOk: true,
	branchGetByIdMessages: [],
	branchAddRequest: false,
	branchAddOk: true,
	branchAddMessages: [],
	branchEditRequest: false,
	branchEditOk: true,
	branchEditMessages: [],
	branchDeleteRequest: false,
	branchDeleteOk: true,
	branchDeleteMessages: [],
	branches: [],
	branch: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.BRANCH_GETALL_START:
			return {
				...state,
				branchGetAllRequest: true,
				branchGetAllOk: true,
				branchGetAllMessages: [],
				branches: initialState.branches
			};
		case actionTypes.BRANCH_GETALL_SUCCESS:
			return {
				...state,
				branchGetAllRequest: false,
				branchGetAllOk: true,
				branchGetAllMessages: [],
				branches: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.BRANCH_GETALL_ERROR:
			return {
				...state,
				branchGetAllRequest: false,
				branchGetAllOk: false,
				branchGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.BRANCH_GETBYID_START:
			return {
				...state,
				branchGetByIdRequest: true,
				branchGetByIdOk: true,
				branchGetByIdMessages: [],
				branch: null
			};
		case actionTypes.BRANCH_GETBYID_SUCCESS:
			return {
				...state,
				branchGetByIdRequest: false,
				branchGetByIdOk: true,
				branchGetByIdMessages: [],
				branch: action.payload
			};
		case actionTypes.BRANCH_GETBYID_ERROR:
			return {
				...state,
				branchGetByIdRequest: false,
				branchGetByIdOk: false,
				branchGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.BRANCH_ADD_START:
			return {
				...state,
				branchAddRequest: true,
				branchAddMessages: [],
				branchAddOk: true,
			};
		case actionTypes.BRANCH_ADD_SUCCESS:
			return {
				...state,
				branchAddRequest: false,
				branchAddOk: true,
				branchAddMessages: [],
				branches: [
					...state.branches,
					action.payload
				]
			};
		case actionTypes.BRANCH_ADD_ERROR:
			return {
				...state,
				branchAddRequest: false,
				branchAddOk: false,
				branchAddMessages: deepCopy(action.payload)
			};
		case actionTypes.BRANCH_EDIT_START:
			return {
				...state,
				branchEditRequest: true,
				branchEditMessages: [],
				branchEditOk: true
			};
		case actionTypes.BRANCH_EDIT_SUCCESS:
			return {
				...state,
				branchEditRequest: false,
				branchEditOk: true,
				branchEditMessages: [],
				branches: [
					...state.branches.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.BRANCH_EDIT_ERROR:
			return {
				...state,
				branchEditRequest: false,
				branchEditOk: false,
				branchEditMessages: deepCopy(action.payload)
			};
		case actionTypes.BRANCH_DELETE_START:
			return {
				...state,
				branchDeleteRequest: true,
				branchDeleteMessages: [],
				branchDeleteOk: true
			};
		case actionTypes.BRANCH_DELETE_SUCCESS:
			return {
				...state,
				branchDeleteRequest: false,
				branchDeleteOk: true,
				branchDeleteMessages: [],
				branches: [
					...state.branches.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.BRANCH_DELETE_ERROR:
			return {
				...state,
				branchDeleteRequest: false,
				branchDeleteOk: false,
				branchDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
