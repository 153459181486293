import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	movementTypeGetAllRequest: false,
	movementTypeGetAllOk: true,
	movementTypeGetAllMessages: [],
	movementTypeGetByIdRequest: false,
	movementTypeGetByIdOk: true,
	movementTypeGetByIdMessages: [],
	movementTypeAddRequest: false,
	movementTypeAddOk: true,
	movementTypeAddMessages: [],
	movementTypeEditRequest: false,
	movementTypeEditOk: true,
	movementTypeEditMessages: [],
	movementTypeDeleteRequest: false,
	movementTypeDeleteOk: true,
	movementTypeDeleteMessages: [],
	movementTypes: [],
	movementType: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.MOVEMENTTYPE_GETALL_START:
			return {
				...state,
				movementTypeGetAllRequest: true,
				movementTypeGetAllOk: true,
				movementTypeGetAllMessages: [],
				movementTypes: initialState.movementTypes
			};
		case actionTypes.MOVEMENTTYPE_GETALL_SUCCESS:
			return {
				...state,
				movementTypeGetAllRequest: false,
				movementTypeGetAllOk: true,
				movementTypeGetAllMessages: [],
				movementTypes: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.MOVEMENTTYPE_GETALL_ERROR:
			return {
				...state,
				movementTypeGetAllRequest: false,
				movementTypeGetAllOk: false,
				movementTypeGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENTTYPE_GETBYID_START:
			return {
				...state,
				movementTypeGetByIdRequest: true,
				movementTypeGetByIdOk: true,
				movementTypeGetByIdMessages: [],
				movementType: null
			};
		case actionTypes.MOVEMENTTYPE_GETBYID_SUCCESS:
			return {
				...state,
				movementTypeGetByIdRequest: false,
				movementTypeGetByIdOk: true,
				movementTypeGetByIdMessages: [],
				movementType: action.payload
			};
		case actionTypes.MOVEMENTTYPE_GETBYID_ERROR:
			return {
				...state,
				movementTypeGetByIdRequest: false,
				movementTypeGetByIdOk: false,
				movementTypeGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENTTYPE_ADD_START:
			return {
				...state,
				movementTypeAddRequest: true,
				movementTypeAddMessages: [],
				movementTypeAddOk: true,
			};
		case actionTypes.MOVEMENTTYPE_ADD_SUCCESS:
			return {
				...state,
				movementTypeAddRequest: false,
				movementTypeAddOk: true,
				movementTypeAddMessages: [],
				movementTypes: [
					...state.movementTypes,
					action.payload
				]
			};
		case actionTypes.MOVEMENTTYPE_ADD_ERROR:
			return {
				...state,
				movementTypeAddRequest: false,
				movementTypeAddOk: false,
				movementTypeAddMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENTTYPE_EDIT_START:
			return {
				...state,
				movementTypeEditRequest: true,
				movementTypeEditMessages: [],
				movementTypeEditOk: true
			};
		case actionTypes.MOVEMENTTYPE_EDIT_SUCCESS:
			return {
				...state,
				movementTypeEditRequest: false,
				movementTypeEditOk: true,
				movementTypeEditMessages: [],
				movementTypes: [
					...state.movementTypes.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.MOVEMENTTYPE_EDIT_ERROR:
			return {
				...state,
				movementTypeEditRequest: false,
				movementTypeEditOk: false,
				movementTypeEditMessages: deepCopy(action.payload)
			};
		case actionTypes.MOVEMENTTYPE_DELETE_START:
			return {
				...state,
				movementTypeDeleteRequest: true,
				movementTypeDeleteMessages: [],
				movementTypeDeleteOk: true
			};
		case actionTypes.MOVEMENTTYPE_DELETE_SUCCESS:
			return {
				...state,
				movementTypeDeleteRequest: false,
				movementTypeDeleteOk: true,
				movementTypeDeleteMessages: [],
				movementTypes: [
					...state.movementTypes.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.MOVEMENTTYPE_DELETE_ERROR:
			return {
				...state,
				movementTypeDeleteRequest: false,
				movementTypeDeleteOk: false,
				movementTypeDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
