import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './stores';
import App from './components/App';

const store = configureStore();

function renderApp(Component) {
	ReactDOM.render(
		<Provider store={store}>
			<Component />
		</Provider>,
		document.getElementById('root')
	);
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('./components/App', () => {
		renderApp(App);
	});
}

renderApp(App);
