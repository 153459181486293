const navigation = {
	items: [
		{
			name: 'Dispositivos',
			url: '/hardware',
			icon: 'fa fa-fw fa-desktop',
		},
		{
			name: 'Comunicaciones',
			url: '/communication',
			icon: 'fa fa-fw fa-tty',
		},
		{
			name: 'Configuración',
			icon: 'fa fa-fw fa-cogs',
			children: [
				{
					name: 'Empresa',
					url: '/company'
				},
				{
					name: 'Sucursal',
					url: '/branch'
				},
				{
					name: 'Departamento',
					url: '/department'
				},
				{
					name: 'Marca',
					url: '/brand'
				},
				{
					name: 'Modelo',
					url: '/model'
				},
				{
					name: 'Proveedor',
					url: '/supplier'
				},
				{
					name: 'Tipo de dispositivo',
					url: '/hardwareType'
				},
				{
					name: 'Tipo de línea',
					url: '/communicationType'
				},
				{
					name: 'Tecnología',
					url: 'communicationTechnology'
				},
				{
					name: 'Uso de comunicación',
					url: '/communicationUses'
				},
				{
					name: 'Servicio',
					url: '/service'
				}
			]
		}
	]
};

export default navigation;
