import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Supplier,
		path: '/supplier',
		exact: true,
		name: 'Proveedor',
		component: Loadable({
			loader: () => import('~/routes/Supplier/SupplierList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.SupplierAdd,
		path: '/supplier/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Supplier/SupplierAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.SupplierEdit,
		path: '/supplier/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Supplier/SupplierEdit'),
			loading: Loading,
		})
	}
];
