import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	hardwareTypeGetAllRequest: false,
	hardwareTypeGetAllOk: true,
	hardwareTypeGetAllMessages: [],
	hardwareTypeGetByIdRequest: false,
	hardwareTypeGetByIdOk: true,
	hardwareTypeGetByIdMessages: [],
	hardwareTypeAddRequest: false,
	hardwareTypeAddOk: true,
	hardwareTypeAddMessages: [],
	hardwareTypeEditRequest: false,
	hardwareTypeEditOk: true,
	hardwareTypeEditMessages: [],
	hardwareTypeDeleteRequest: false,
	hardwareTypeDeleteOk: true,
	hardwareTypeDeleteMessages: [],
	hardwareTypes: [],
	hardwareType: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.HARDWARETYPE_GETALL_START:
			return {
				...state,
				hardwareTypeGetAllRequest: true,
				hardwareTypeGetAllOk: true,
				hardwareTypeGetAllMessages: [],
				hardwareTypes: initialState.hardwareTypes
			};
		case actionTypes.HARDWARETYPE_GETALL_SUCCESS:
			return {
				...state,
				hardwareTypeGetAllRequest: false,
				hardwareTypeGetAllOk: true,
				hardwareTypeGetAllMessages: [],
				hardwareTypes: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.HARDWARETYPE_GETALL_ERROR:
			return {
				...state,
				hardwareTypeGetAllRequest: false,
				hardwareTypeGetAllOk: false,
				hardwareTypeGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARETYPE_GETBYID_START:
			return {
				...state,
				hardwareTypeGetByIdRequest: true,
				hardwareTypeGetByIdOk: true,
				hardwareTypeGetByIdMessages: [],
				hardwareType: null
			};
		case actionTypes.HARDWARETYPE_GETBYID_SUCCESS:
			return {
				...state,
				hardwareTypeGetByIdRequest: false,
				hardwareTypeGetByIdOk: true,
				hardwareTypeGetByIdMessages: [],
				hardwareType: action.payload
			};
		case actionTypes.HARDWARETYPE_GETBYID_ERROR:
			return {
				...state,
				hardwareTypeGetByIdRequest: false,
				hardwareTypeGetByIdOk: false,
				hardwareTypeGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARETYPE_ADD_START:
			return {
				...state,
				hardwareTypeAddRequest: true,
				hardwareTypeAddMessages: [],
				hardwareTypeAddOk: true,
			};
		case actionTypes.HARDWARETYPE_ADD_SUCCESS:
			return {
				...state,
				hardwareTypeAddRequest: false,
				hardwareTypeAddOk: true,
				hardwareTypeAddMessages: [],
				hardwareTypes: [
					...state.hardwareTypes,
					action.payload
				]
			};
		case actionTypes.HARDWARETYPE_ADD_ERROR:
			return {
				...state,
				hardwareTypeAddRequest: false,
				hardwareTypeAddOk: false,
				hardwareTypeAddMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARETYPE_EDIT_START:
			return {
				...state,
				hardwareTypeEditRequest: true,
				hardwareTypeEditMessages: [],
				hardwareTypeEditOk: true
			};
		case actionTypes.HARDWARETYPE_EDIT_SUCCESS:
			return {
				...state,
				hardwareTypeEditRequest: false,
				hardwareTypeEditOk: true,
				hardwareTypeEditMessages: [],
				hardwareTypes: [
					...state.hardwareTypes.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.HARDWARETYPE_EDIT_ERROR:
			return {
				...state,
				hardwareTypeEditRequest: false,
				hardwareTypeEditOk: false,
				hardwareTypeEditMessages: deepCopy(action.payload)
			};
		case actionTypes.HARDWARETYPE_DELETE_START:
			return {
				...state,
				hardwareTypeDeleteRequest: true,
				hardwareTypeDeleteMessages: [],
				hardwareTypeDeleteOk: true
			};
		case actionTypes.HARDWARETYPE_DELETE_SUCCESS:
			return {
				...state,
				hardwareTypeDeleteRequest: false,
				hardwareTypeDeleteOk: true,
				hardwareTypeDeleteMessages: [],
				hardwareTypes: [
					...state.hardwareTypes.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.HARDWARETYPE_DELETE_ERROR:
			return {
				...state,
				hardwareTypeDeleteRequest: false,
				hardwareTypeDeleteOk: false,
				hardwareTypeDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
