import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.CommunicationTechnology,
		path: '/communicationTechnology',
		exact: true,
		name: 'Tecnología',
		component: Loadable({
			loader: () => import('~/routes/CommunicationTechnology/CommunicationTechnologyList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationTechnologyAdd,
		path: '/communicationTechnology/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/CommunicationTechnology/CommunicationTechnologyAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.CommunicationTechnologyEdit,
		path: '/communicationTechnology/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/CommunicationTechnology/CommunicationTechnologyEdit'),
			loading: Loading,
		})
	}
];
