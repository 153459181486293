import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import rootReducer from '../reducers';

export default function configureStore(initialState) {
	const middlewares = [thunkMiddleware];

	if (process.env.NODE_ENV !== 'production') {
		const logger = createLogger({
			collapsed: true,
			duration: true,
		});
		middlewares.push(logger);
	}

	const middlewareEnhancer = applyMiddleware(...middlewares);
	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevTools(...enhancers);
	const store = createStore(rootReducer, initialState, composedEnhancers);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('../reducers', () => {
			store.replaceReducer(rootReducer);
		});
	}

	return store;
}
