export default {
	DEPARTMENT_GETALL_START: 'DEPARTMENT_GETALL_START',
	DEPARTMENT_GETALL_SUCCESS: 'DEPARTMENT_GETALL_SUCCESS',
	DEPARTMENT_GETALL_ERROR: 'DEPARTMENT_GETALL_ERROR',
	DEPARTMENT_GETBYID_START: 'DEPARTMENT_GETBYID_START',
	DEPARTMENT_GETBYID_SUCCESS: 'DEPARTMENT_GETBYID_SUCCESS',
	DEPARTMENT_GETBYID_ERROR: 'DEPARTMENT_GETBYID_ERROR',
	DEPARTMENT_ADD_START: 'DEPARTMENT_ADD_START',
	DEPARTMENT_ADD_SUCCESS: 'DEPARTMENT_ADD_SUCCESS',
	DEPARTMENT_ADD_ERROR: 'DEPARTMENT_ADD_ERROR',
	DEPARTMENT_EDIT_START: 'DEPARTMENT_EDIT_START',
	DEPARTMENT_EDIT_SUCCESS: 'DEPARTMENT_EDIT_SUCCESS',
	DEPARTMENT_EDIT_ERROR: 'DEPARTMENT_EDIT_ERROR',
	DEPARTMENT_DELETE_START: 'DEPARTMENT_DELETE_START',
	DEPARTMENT_DELETE_SUCCESS: 'DEPARTMENT_DELETE_SUCCESS',
	DEPARTMENT_DELETE_ERROR: 'DEPARTMENT_DELETE_ERROR',
};
