import Loadable from 'react-loadable';
import Loading from '~/components/Shared/Loading';
import routesIdMap from './RoutesIdMap';

export default [
	{
		id: routesIdMap.Brand,
		path: '/brand',
		exact: true,
		name: 'Marca',
		component: Loadable({
			loader: () => import('~/routes/Brand/BrandList'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.BrandAdd,
		path: '/brand/Add',
		exact: true,
		name: 'Añadir',
		component: Loadable({
			loader: () => import('~/routes/Brand/BrandAdd'),
			loading: Loading,
		})
	},
	{
		id: routesIdMap.BrandEdit,
		path: '/brand/Edit/:id',
		exact: true,
		name: 'Editar',
		component: Loadable({
			loader: () => import('~/routes/Brand/BrandEdit'),
			loading: Loading,
		})
	}
];
