import actionTypes from '~/constants/ActionTypes';
import { deepCopy } from '~/helpers';

const initialState = {
	communicationTypeGetAllRequest: false,
	communicationTypeGetAllOk: true,
	communicationTypeGetAllMessages: [],
	communicationTypeGetByIdRequest: false,
	communicationTypeGetByIdOk: true,
	communicationTypeGetByIdMessages: [],
	communicationTypeAddRequest: false,
	communicationTypeAddOk: true,
	communicationTypeAddMessages: [],
	communicationTypeEditRequest: false,
	communicationTypeEditOk: true,
	communicationTypeEditMessages: [],
	communicationTypeDeleteRequest: false,
	communicationTypeDeleteOk: true,
	communicationTypeDeleteMessages: [],
	communicationTypes: [],
	communicationType: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CLEAR_CACHE:
			return initialState;
		case actionTypes.COMMUNICATIONTYPE_GETALL_START:
			return {
				...state,
				communicationTypeGetAllRequest: true,
				communicationTypeGetAllOk: true,
				communicationTypeGetAllMessages: [],
				communicationTypes: initialState.communicationTypes
			};
		case actionTypes.COMMUNICATIONTYPE_GETALL_SUCCESS:
			return {
				...state,
				communicationTypeGetAllRequest: false,
				communicationTypeGetAllOk: true,
				communicationTypeGetAllMessages: [],
				communicationTypes: deepCopy(action.payload.map(x => ({ ...x })))
			};
		case actionTypes.COMMUNICATIONTYPE_GETALL_ERROR:
			return {
				...state,
				communicationTypeGetAllRequest: false,
				communicationTypeGetAllOk: false,
				communicationTypeGetAllMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTYPE_GETBYID_START:
			return {
				...state,
				communicationTypeGetByIdRequest: true,
				communicationTypeGetByIdOk: true,
				communicationTypeGetByIdMessages: [],
				communicationType: null
			};
		case actionTypes.COMMUNICATIONTYPE_GETBYID_SUCCESS:
			return {
				...state,
				communicationTypeGetByIdRequest: false,
				communicationTypeGetByIdOk: true,
				communicationTypeGetByIdMessages: [],
				communicationType: action.payload
			};
		case actionTypes.COMMUNICATIONTYPE_GETBYID_ERROR:
			return {
				...state,
				communicationTypeGetByIdRequest: false,
				communicationTypeGetByIdOk: false,
				communicationTypeGetByIdMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTYPE_ADD_START:
			return {
				...state,
				communicationTypeAddRequest: true,
				communicationTypeAddMessages: [],
				communicationTypeAddOk: true,
			};
		case actionTypes.COMMUNICATIONTYPE_ADD_SUCCESS:
			return {
				...state,
				communicationTypeAddRequest: false,
				communicationTypeAddOk: true,
				communicationTypeAddMessages: [],
				communicationTypes: [
					...state.communicationTypes,
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONTYPE_ADD_ERROR:
			return {
				...state,
				communicationTypeAddRequest: false,
				communicationTypeAddOk: false,
				communicationTypeAddMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTYPE_EDIT_START:
			return {
				...state,
				communicationTypeEditRequest: true,
				communicationTypeEditMessages: [],
				communicationTypeEditOk: true
			};
		case actionTypes.COMMUNICATIONTYPE_EDIT_SUCCESS:
			return {
				...state,
				communicationTypeEditRequest: false,
				communicationTypeEditOk: true,
				communicationTypeEditMessages: [],
				communicationTypes: [
					...state.communicationTypes.filter(x => x.id !== action.payload.id),
					action.payload
				]
			};
		case actionTypes.COMMUNICATIONTYPE_EDIT_ERROR:
			return {
				...state,
				communicationTypeEditRequest: false,
				communicationTypeEditOk: false,
				communicationTypeEditMessages: deepCopy(action.payload)
			};
		case actionTypes.COMMUNICATIONTYPE_DELETE_START:
			return {
				...state,
				communicationTypeDeleteRequest: true,
				communicationTypeDeleteMessages: [],
				communicationTypeDeleteOk: true
			};
		case actionTypes.COMMUNICATIONTYPE_DELETE_SUCCESS:
			return {
				...state,
				communicationTypeDeleteRequest: false,
				communicationTypeDeleteOk: true,
				communicationTypeDeleteMessages: [],
				communicationTypes: [
					...state.communicationTypes.filter(x => x.id !== action.payload)
				]
			};
		case actionTypes.COMMUNICATIONTYPE_DELETE_ERROR:
			return {
				...state,
				communicationTypeDeleteRequest: false,
				communicationTypeDeleteOk: false,
				communicationTypeDeleteMessages: deepCopy(action.payload)
			};
		default:
			return state;
	}
};
