export default {
	MODEL_GETALL_START: 'MODEL_GETALL_START',
	MODEL_GETALL_SUCCESS: 'MODEL_GETALL_SUCCESS',
	MODEL_GETALL_ERROR: 'MODEL_GETALL_ERROR',
	MODEL_GETBYID_START: 'MODEL_GETBYID_START',
	MODEL_GETBYID_SUCCESS: 'MODEL_GETBYID_SUCCESS',
	MODEL_GETBYID_ERROR: 'MODEL_GETBYID_ERROR',
	MODEL_ADD_START: 'MODEL_ADD_START',
	MODEL_ADD_SUCCESS: 'MODEL_ADD_SUCCESS',
	MODEL_ADD_ERROR: 'MODEL_ADD_ERROR',
	MODEL_EDIT_START: 'MODEL_EDIT_START',
	MODEL_EDIT_SUCCESS: 'MODEL_EDIT_SUCCESS',
	MODEL_EDIT_ERROR: 'MODEL_EDIT_ERROR',
	MODEL_DELETE_START: 'MODEL_DELETE_START',
	MODEL_DELETE_SUCCESS: 'MODEL_DELETE_SUCCESS',
	MODEL_DELETE_ERROR: 'MODEL_DELETE_ERROR'
};
